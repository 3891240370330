import React, { useEffect, useRef, useState, lazy } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from "react-select";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//thunks
import { ActualizarForm, setDisabled} from '../../slices/vendor/vendorSlice';
import {   
  getDataToLocalStorage, 
  updateVendor, updateObjectIddir,
  generatePDF, sendFormTemp
} from "../../slices/vendor/vendorThunks";

//validation
import { generateValidationSchema } from '../../utils/validationsSchemaVendorNacional';
//axios
import { instanceAxiosPrivate } from '../../axios';
import { getTextContentByName } from '../../utils/getTextContentByName';
import Modal from '../modal/Modal';
import { PopoverCustom } from '../popover/PopoverCustom';

const Checkbox = lazy(() => import ('../form/checkbox/Checkbox'));

const tcuenta = [
    { value: 1, label: "Ahorros" },
    { value: 2, label: "Corriente" }
]

const tpersona = [
    { value: 1, label: "Persona Juridica" },
    { value: 2, label: "Persona Natural" }
]

export const Nacional = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //traduccion
    const { t } = useTranslation();
    //state
    const [validationSchema, setValidationSchema] = useState(null); 
    const [showModal, setShowModal] = useState(false);
    const [showModalPreliminar, setShowModalPreliminar] = useState(false);
    //data formulario
    const { 
        form, disabled, documenttype,
        paisdane, departamentodane, ciudaddane,
        glosariotipoempresa,
        codigopostal, regimentrib,
        banklist, required, pdf
    } = useSelector((state) => state.form_vendor);
    //referencia 
    const check_habeas_data = useRef();
    //modales
    const modalHide = () => { setShowModal(false); }
    const modalShow = () => { setShowModal(true); }
    const modalHidePreliminar = () => { setShowModalPreliminar(false); }
    const modalShowPreliminar = () => { setShowModalPreliminar(true);  }
    //form
    const { 
        register,
        reset,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });
    //preliminar form 
    const onPreview = async () => {
        let form = document.querySelector('form');
        const formData = new FormData(form);
        let values = Object.fromEntries(formData);
        let valform = values;
        valform.tipo_compania = getTextContentByName('tipo_compania');
        valform.tipo_documento = getTextContentByName('tipo_documento');
        valform.codigo_postal = getTextContentByName('codigo_postal');
        valform.pais = getTextContentByName('pais');
        valform.departamento = getTextContentByName('departamento');
        valform.ciudad = getTextContentByName('ciudad');
        valform.ciudad_nombre = getTextContentByName('ciudad');
        valform.regimen_tributario = getTextContentByName('regimen_tributario');
        valform.tamano_empresa = getTextContentByName('tamano_empresa');
        valform.entidad_financiera = getTextContentByName('entidad_financiera');
        valform.tipo_cuenta = getTextContentByName('tipo_cuenta');
        valform.tipo_documento_rep = getTextContentByName('tipo_documento_rep');
        valform.numero_documento = getValues('numero_documento');
        // validate numero de documento
        let obj = {
        templatefile:'formulario_registrodos', 
        values: valform,
        filename: `register_vendor_${getValues('numero_documento')}`,
        format: "A4"
        }
        //generate pdf
        dispatch(generatePDF(obj)); 
        //show modal with pdf
        setTimeout(() => {
        modalShowPreliminar();      
        }, 2500);
    }
    //send form
    const onSubmit = async (values) => {          
        for (var key in values) if (key.startsWith("iddir_")) delete values[key];
        for (var llave in values) if (llave.startsWith("origen_")) delete values[llave];
        values.ciudad_nombre = getTextContentByName('ciudad');
        values.draft='N';
        dispatch(ActualizarForm(values));
        Swal.fire({
            title: t('sure'),
            text: t('suretext'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('sureyesbutton'),
            cancelButtonText: t('surecancelbutton')
        }).then((result) => {
            if (result.isConfirmed) {
            try {
                dispatch(updateVendor());     
            } catch (error) {
                toast.error(error);
            }
            } 
        }) 
    }
   //send form
    const onTemp = async () => {          
        let form = document.querySelector('form');
        const formData = new FormData(form);
        let values = Object.fromEntries(formData);
        values.ciudad_nombre = getTextContentByName('ciudad');
        values.draft = 'S';
        Swal.fire({
            title: t('suretexttemp'),
            text: t('sure'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('sureyestmpbutton'),
            cancelButtonText: t('surecancelbutton')
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                dispatch(ActualizarForm(values));
                dispatch(sendFormTemp());     
                } catch (error) {
                toast.error(error);
                }
            } 
        })
    }
    // save file window
    const handleFileChange =  (event, r) => {
        const file = event.target.files[0];        
        //guardar el archivo
        const instA = new instanceAxiosPrivate();
        instA.setItemGeneral(`iddir_${r.iddir}`, file);
        if(file){
            setValue(`iddir_${r.iddir}`, instA.getItemGeneral(`iddir_${r.iddir}`)); 
        }
        //actualizar el state
        dispatch(updateObjectIddir({
            iddir: r.iddir,
            name_dir: r.name_dir,
            idparent: r.idparent,
            name_file: file.name,
            mime: r.mime,
            size: file.size,
            type_document: 1
        }));
    }
    //reset values localstorage
    useEffect(() => {    
        const localS = localStorage.getItem("form_vendor")
        const localSparse = JSON.parse(localS);
        if(localSparse?.numero_documento !== 0 && localSparse !== null ) {
        reset({
            //form 1
            numero_documento: localSparse?.numero_documento,
            tipo_compania: localSparse?.tipo_compania,
            tipo_documento: localSparse?.tipo_documento,
            razon_social: localSparse?.razon_social,
            direccion_principal: localSparse?.direccion_principal,
            codigo_postal: localSparse?.codigo_postal,
            telefono_fijo: localSparse?.telefono_fijo,
            telefono_celular: localSparse?.telefono_celular,
            correo_empresarial: localSparse?.correo_empresarial,
            pais: localSparse?.pais,
            departamento: localSparse?.departamento,
            ciudad: localSparse?.ciudad,
            ciudad_nombre: localSparse?.ciudad_nombre,
            matricula_mercantil: localSparse?.matricula_mercantil,
            sagrilaft: localSparse?.sagrilaft,
            sarlaft: localSparse?.sarlaft,
            prevension_lavados_activos: localSparse?.prevension_lavados_activos,
            //form 2
            regimen_tributario: localSparse?.regimen_tributario,
            responsable_iva: localSparse?.responsable_iva,
            //form 3
            nombre_rep: localSparse?.nombre_rep,
            tipo_documento_rep: localSparse?.tipo_documento_rep,
            numero_de_identidad_rep: localSparse?.numero_de_identidad_rep,
            correo_electronico_rep: localSparse?.correo_electronico_rep,
            numero_telefonico_rep: localSparse?.numero_telefonico_rep,
            persona_expuesta: localSparse?.persona_expuesta,
            //form 4
            tamano_empresa: localSparse?.tamano_empresa,
            entidad_financiera: localSparse?.entidad_financiera,
            numero_cuenta: localSparse?.numero_cuenta,
            tipo_cuenta: localSparse?.tipo_cuenta,
            //form 5
            nombre_contacto_financiero: localSparse?.nombre_contacto_financiero,
            cargo_contacto_financiero: localSparse?.cargo_contacto_financiero,
            telefono_contacto_financiero: localSparse?.telefono_contacto_financiero,
            correo_electronico_financiero: localSparse?.correo_electronico_financiero,
            //form 6
            nombre_contacto_comercial: localSparse?.nombre_contacto_comercial,
            cargo_contacto_comercial: localSparse?.cargo_contacto_comercial,
            telefono_contacto_comercial: localSparse?.telefono_contacto_comercial,
            correo_electronico_contacto_comercial: localSparse?.correo_electronico_contacto_comercial,
            expbienes_servicios: localSparse?.expbienes_servicios,
            //form 7
            nombre_contacto_lg: localSparse?.nombre_contacto_lg,
            cargo_contacto_lg: localSparse?.cargo_contacto_lg,
            telefono_contacto_lg: localSparse?.telefono_contacto_lg,
            correo_electronico_contacto_lg: localSparse?.correo_electronico_contacto_lg,
            check_habeas_data: localSparse?.check_habeas_data
        })
        dispatch(getDataToLocalStorage())
        dispatch(setDisabled(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
    //reset values redux
    useEffect(() => {
        if(form.numero_documento !== 0) {
        reset({
            //form 1
            numero_documento: form?.numero_documento,
            tipo_compania: form?.tipo_compania,
            tipo_documento: form?.tipo_documento,
            razon_social: form?.razon_social,
            direccion_principal: form?.direccion_principal,
            codigo_postal: form?.codigo_postal,
            telefono_fijo: form?.telefono_fijo,
            telefono_celular: form?.telefono_celular,
            correo_empresarial: form?.correo_empresarial,
            pais: form?.pais,
            departamento: form?.departamento,
            ciudad: form?.ciudad,
            ciudad_nombre: form?.ciudad_nombre,
            matricula_mercantil: form?.matricula_mercantil,
            sagrilaft: form?.sagrilaft,
            sarlaft: form?.sarlaft,
            prevension_lavados_activos: form?.prevension_lavados_activos,
            //form 2
            regimen_tributario: form?.regimen_tributario,
            responsable_iva: form?.responsable_iva,
            //form 3
            nombre_rep: form?.nombre_rep,
            tipo_documento_rep: form?.tipo_documento_rep,
            numero_de_identidad_rep: form?.numero_de_identidad_rep,
            correo_electronico_rep: form?.correo_electronico_rep,
            numero_telefonico_rep: form?.numero_telefonico_rep,
            persona_expuesta: form?.persona_expuesta,
            //form 4
            tamano_empresa: form?.tamano_empresa,
            entidad_financiera: form?.entidad_financiera,
            numero_cuenta: form?.numero_cuenta,
            tipo_cuenta: form?.tipo_cuenta,
            //form 5
            nombre_contacto_financiero: form?.nombre_contacto_financiero,
            cargo_contacto_financiero: form?.cargo_contacto_financiero,
            telefono_contacto_financiero: form?.telefono_contacto_financiero,
            correo_electronico_financiero: form?.correo_electronico_financiero,
            //form 6
            nombre_contacto_comercial: form?.nombre_contacto_comercial,
            cargo_contacto_comercial: form?.cargo_contacto_comercial,
            telefono_contacto_comercial: form?.telefono_contacto_comercial,
            correo_electronico_contacto_comercial: form?.correo_electronico_contacto_comercial,
            expbienes_servicios: form?.expbienes_servicios,
            //form 7
            nombre_contacto_lg: form?.nombre_contacto_lg,
            cargo_contacto_lg: form?.cargo_contacto_lg,
            telefono_contacto_lg: form?.telefono_contacto_lg,
            correo_electronico_contacto_lg: form?.correo_electronico_contacto_lg,
            check_habeas_data: form?.check_habeas_data
        })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, form?.numero_documento]); 
    //validaciones
    useEffect(() => {
        if(form?.iddir?.length > 0){
        const schema = generateValidationSchema(form?.iddir);
        setValidationSchema(schema);
        }
    }, [form?.iddir])

    return (
        <>
        <Modal 
            handleClose={modalHide} 
            isOpen={showModal} 
            header={t('titleauth')} 
            invoiceNum={' '} 
        >
            <div className="container-fluid py-2">    
                <p className='text-justify'>
                    { t('auth1')}
                    <Link 
                        to="https://www.lg.com/co/privacy/#tabs-privacy-policy" 
                        target="_blank" className='text-decoration-none text-gray font-weight-bold'
                    >
                        { t('politic_data') }
                    </Link> 
                    { t('of')} LG ELECTRONICS COLOMBIA LTDA.
                </p>
                <p className='text-justify'>{ t('auth2')}</p>
            </div>
        </Modal>
        <Modal 
            handleClose={modalHidePreliminar} 
            isOpen={showModalPreliminar} 
            header='' 
            invoiceNum={' '} 
        >
            <div className="container-fluid py-2 justify-content-center">
                {  pdf !== '' 
                    ? ( 
                        <embed 
                        type='application/pdf'
                        src={pdf} 
                        title="Registro Preliminar"  
                        width="900" height="600" 
                        style={{width: "100%"}}
                        /> 
                    ) : (  <p> No hay datos </p> ) 
                }  
            </div>
        </Modal>
        
        <form onSubmit={handleSubmit(onSubmit)} className='mt-1'>
            <div className="row py-1">
                <div className='col-12'>
                    <h5 className='mt-2 mb-0'>{ t('general_supplier_information') }</h5>
                    <hr className="col-12 ml-0 my-1 px-0 py-0"/>
                </div>
                <div className='col-12 col-sm-6 col-md-4'>
                    <label>{t('company_type')}</label> 
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = tpersona && tpersona.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={tpersona}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder={`${t('select')}`}
                                />
                            )                      
                        }}
                        name="tipo_compania"   
                    />
                    {errors.tipo_compania && (<p className='text-danger mb-0'>{errors ? errors.tipo_compania?.message : ''}</p>)}
                </div>
                <div className="col-12 col-sm-6 col-md-5">
                    <label>{t('document_type')}</label> 
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = documenttype && documenttype.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={documenttype}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder={`${t('select')}`}
                                />
                            )
                        }}
                        name="tipo_documento" 
                        />
                    {errors.tipo_documento && (<p className='text-danger mb-0'>{errors ? errors.tipo_documento?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-6 '>
                    <label>{t('name_or_company_name')}</label>  
                    <input type="text" className='form-control'  {...register("razon_social")} disabled={disabled}/>
                    {errors.razon_social && (<p className='text-danger mb-0'>{errors ? errors.razon_social?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-6'>
                    <label>{t('primary_home_address')}</label>  
                    <input type="text" className='form-control' {...register("direccion_principal") } disabled={disabled}/>
                    {errors.direccion_principal && (<p className='text-danger mb-0'>{errors ? errors.direccion_principal?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-6'>
                    <label>{t('postal_code')}</label>  
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = codigopostal && codigopostal.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={codigopostal}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder={`${t('select')}`}
                                />
                            )
                        }}
                        name="codigo_postal" 
                    />
                    {errors.codigo_postal && (<p className='text-danger mb-0'>{errors ? errors.codigo_postal?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-6 col-md-3'>
                    <label>{t('landline_telephone')}</label> 
                    <input type="number" className='form-control' {...register("telefono_fijo")} disabled={disabled}/>
                    {errors.telefono_fijo && (<p className='text-danger mb-0'>{errors ? errors.telefono_fijo?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-6 col-md-3'>
                    <label>{t('cellphone_no')}</label> 
                    <input type="number" className='form-control' {...register("telefono_celular")} disabled={disabled}/>
                    {errors.telefono_celular && (<p className='text-danger mb-0'>{errors ? errors.telefono_celular?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-12'>
                    <label>{t('email_notifications')}</label>  
                    <input type="email" className='form-control' {...register("correo_empresarial")} disabled={disabled}/>
                    {errors.correo_empresarial && (<p className='text-danger mb-0'>{errors ? errors.correo_empresarial?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-4 col-md-4'>
                    <label>{t('country')}</label>  
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = paisdane && paisdane.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={paisdane}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder={`${t('select')}`}
                                />
                            )
                        }}
                        name="pais" 
                    />
                    {errors.pais && (<p className='text-danger mb-0'>{errors ? errors.pais?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-4 col-md-4'>
                    <label>{t('department_state')}</label>   
                    <Controller
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = departamentodane && departamentodane.find((c) => c.value === value )
                        const selectChange = (selected) => onChange(selected.value) 
                        return (
                            <Select
                                label=""
                                isDisabled={disabled}
                                instaceId="selectbox"
                                ref={ref}
                                value={currentSelection}
                                name={name}
                                options={departamentodane}
                                theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                                })}
                                className='react-select-container'
                                onChange={selectChange}
                                placeholder={`${t('select')}`}
                            />
                        )
                    }}
                    name="departamento" 
                    />
                    {errors.departamento && (<p className='text-danger mb-0'>{errors ? errors.departamento?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-4 col-md-4'>
                    <label>{t('city')}</label>   
                    <Controller
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = ciudaddane && ciudaddane.find((c) => c.value === value )
                        const selectChange = (selected) => {
                        onChange(selected.value)
                        setValue('ciudad_nombre', selected.label); 
                        } 
                        return (
                            <Select
                                label=""
                                isDisabled={disabled}
                                instaceId="selectbox"
                                ref={ref}
                                value={currentSelection}
                                name={name}
                                options={ciudaddane}
                                theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                                })}
                                className='react-select-container'
                                onChange={selectChange}
                                placeholder="Seleccionar...."
                            />
                        )
                    }}
                    name="ciudad" 
                    />
                    {errors.ciudad && (<p className='text-danger mb-0'>{errors ? errors.ciudad?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-4'>
                    <label>{t('commercial_date')}</label>   
                    <input type="date" className='form-control' {...register("matricula_mercantil")} disabled={disabled}/>
                    {errors.matricula_mercantil && (<p className='text-danger mb-0'>{errors ? errors.matricula_mercantil?.message : ''}</p>)}
                </div>
                <div className='col-12 col-sm-6 col-md-4'>
                    <label>{t('sagrilaft')}</label>   
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = required && required.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={required}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder="Seleccionar...."
                                />
                            )
                        }}
                        name="sagrilaft" 
                    />
                    {errors.sagrilaft && (<p className='text-danger mb-0'>{errors ? errors.sagrilaft?.message : ''}</p>)}
                </div>
                
                <div className='col-12 col-sm-6 col-md-4'>
                    <label>{t('sarlaft')}</label>   
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                            const currentSelection = required && required.find((c) => c.value === value )
                            const selectChange = (selected) => onChange(selected.value) 
                            return (
                                <Select
                                    label=""
                                    isDisabled={disabled}
                                    instaceId="selectbox"
                                    ref={ref}
                                    value={currentSelection}
                                    name={name}
                                    options={required}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0
                                    })}
                                    className='react-select-container'
                                    onChange={selectChange}
                                    placeholder="Seleccionar...."
                                />
                            )
                        }}
                        name="sarlaft" 
                    />
                    {errors.sarlaft && (<p className='text-danger mb-0'>{errors ? errors.sarlaft?.message : ''}</p>)}
                </div>
                <div className='col-12 col-md-12'>
                    <label>{t('prevention_program')}</label>   
                    <Controller
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = required && required.find((c) => c.value === value )
                        const selectChange = (selected) => onChange(selected.value) 
                        return (
                            <Select
                                label=""
                                isDisabled={disabled}
                                instaceId="selectbox"
                                ref={ref}
                                value={currentSelection}
                                name={name}
                                options={required}
                                theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                                })}
                                className='react-select-container'
                                onChange={selectChange}
                                placeholder="Seleccionar...."
                            />
                        )
                    }}
                    name="prevension_lavados_activos" 
                    />
                    {errors.prevension_lavados_activos && (<p className='text-danger mb-0'>{errors ? errors.prevension_lavados_activos?.message : ''}</p>)}
                </div>
            </div>
            <div className="row py-1">
            <h5 className='mt-2 mb-0'>{t('information_tax')}</h5>
            <hr className="col-11 ml-2 my-1 py-0"/>
            <div className='col-12 col-sm-6 col-md-4'>
                <label>{t('tax_regime')}</label>
                <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                    const currentSelection = regimentrib && regimentrib.find((c) => c.value === value )
                    const selectChange = (selected) => onChange(selected.value) 
                    return (
                    <Select
                        label=""
                        isDisabled={disabled}
                        instaceId="selectbox"
                        ref={ref}
                        value={currentSelection}
                        name={name}
                        options={regimentrib}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0
                        })}
                        className='react-select-container'
                        onChange={selectChange}
                        placeholder={`${t('select')}`}
                    />
                    )
                }}
                name="regimen_tributario" 
                />
                {errors.regimen_tributario && (<p className='text-danger mb-0'>{errors ? errors.regimen_tributario?.message : ''}</p>)}
            </div>
            <div className="col-12 col-sm-6  col-md-4 px-2">
                <label>{t('vat_manager')}</label>
                <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                    const currentSelection = required && required.find((c) => c.value === value )
                    const selectChange = (selected) => onChange(selected.value) 
                    return (
                    <Select
                        label=""
                        isDisabled={disabled}
                        instaceId="selectbox"
                        ref={ref}
                        value={currentSelection}
                        name={name}
                        options={required}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0
                        })}
                        className='react-select-container'
                        onChange={selectChange}
                        placeholder={`${t('select')}`}
                    />
                    )
                }}
                name="responsable_iva" 
                />
                {errors.responsable_iva && (<p className='text-danger mb-0'>{errors ? errors.responsable_iva?.message : ''}</p>)}
            </div>
            </div>
            <div className="row py-1">
            <h5 className='mt-2 mb-0'>{t('legal_representation')}</h5>
            <hr className="col-11 ml-2 my-1 py-0"/>
            <div className="col-12 col-md-12">
                <label>{t('legal_representative')}</label>  
                <input type="text" className='form-control' {...register("nombre_rep")} disabled={disabled}/>
                {errors.nombre_rep && (<p className='text-danger mb-0'>{errors ? errors.nombre_rep?.message : ''}</p>)}
            </div>
            
            <div className="col-12 col-md-6">
                <label>{t('document_type')}</label> 
                <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                    const currentSelection = documenttype && documenttype.find((c) => c.value === value )
                    const selectChange = (selected) => onChange(selected.value) 
                    return (
                    <Select
                        label=""
                        isDisabled={disabled}
                        instaceId="selectbox"
                        ref={ref}
                        value={currentSelection}
                        name={name}
                        options={documenttype}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0
                        })}
                        className='react-select-container'
                        onChange={selectChange}
                        placeholder="Selecccionar..."
                    />
                    )
                }}
                name="tipo_documento_rep" 
                />
                {errors.tipo_documento_rep && (<p className='text-danger mb-0'>{errors ? errors.tipo_documento_rep?.message : ''}</p>)}
            </div>
            <div className='col-12 col-md-6'>
                <label>{t('document_number')}</label> 
                <div className="input-group mb-0">
                <div className="input-group-prepend">
                    <span className="input-group-text">No.</span>
                </div>
                <input 
                    {...register("numero_de_identidad_rep")}
                    type="number"
                    className='form-control'
                    maxLength={10} 
                    placeholder='Ingresar...'
                    disabled={disabled}
                />
                </div>
                {errors.numero_de_identidad_rep && (<p className='text-danger mb-0'>{errors ? errors.numero_de_identidad_rep?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-12">
                <label>{t('email_contract')}</label>  
                <input type="email" className= 'form-control' {...register("correo_electronico_rep")} disabled={disabled}/>
                {errors.correo_electronico_rep && (<p className='text-danger mb-0'>{errors ? errors.correo_electronico_rep?.message : ''}</p>)}
            </div>
            <div className='col-12 col-md-6'>
                <label>{t('phone_number')}</label>  
                <input type="number" className= 'form-control' {...register("numero_telefonico_rep")} disabled={disabled}/>
                {errors.numero_telefonico_rep && (<p className='text-danger mb-0'>{errors ? errors.numero_telefonico_rep?.message : ''}</p>)}
            </div>
            <div className='col-12 col-md-6'>
                {/* TODO: pasar a un select */}
                <label>{t('publicly_exposed_person')}</label>  
                <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                    const currentSelection = required && required.find((c) => c.value === value )
                    const selectChange = (selected) => onChange(selected.value) 
                    return (
                    <Select
                        label=""
                        isDisabled={disabled}
                        instaceId="selectbox"
                        ref={ref}
                        value={currentSelection}
                        name={name}
                        options={required}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0
                        })}
                        className='react-select-container'
                        onChange={selectChange}
                        placeholder="Seleccionar...."
                    />
                    )
                }}
                name="persona_expuesta" 
                />
                {errors.persona_expuesta && (<p className='text-danger mb-0'>{errors ? errors.persona_expuesta?.message : ''}</p>)}
            </div>
            </div>
            <div className="row py-1">
                <h5 className='mt-2 mb-0'>{t('bank_information_payment')}</h5>
                <hr className="col-11 ml-2 my-1 py-0"/>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <label>{t('company_size')}</label>  
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = glosariotipoempresa && glosariotipoempresa.find((c) => c.value === value )
                        const selectChange = (selected) => onChange(selected.value) 
                        return (
                            <Select
                            label=""
                            isDisabled={disabled}
                            instaceId="selectbox"
                            ref={ref}
                            value={currentSelection}
                            name={name}
                            options={glosariotipoempresa}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                            })}
                            className='react-select-container'
                            onChange={selectChange}
                            placeholder={`${t('select')}`}
                            />
                        )
                        }}
                        name="tamano_empresa" 
                    />
                    {errors.tamano_empresa && (<p className='text-danger mb-0'>{errors ? errors.tamano_empresa?.message : ''}</p>)}
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <label>{t('financial_entity')}</label>  
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = banklist && banklist.find((c) => c.value === value )
                        const selectChange = (selected) => onChange(selected.value) 
                        return (
                            <Select
                            label=""
                            isDisabled={disabled}
                            instaceId="selectbox"
                            ref={ref}
                            value={currentSelection}
                            name={name}
                            options={banklist}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                            })}
                            className='react-select-container'
                            onChange={selectChange}
                            placeholder={`${t('select')}`}
                            />
                        )
                        }}
                        name="entidad_financiera" 
                    />
                    {errors.entidad_financiera && (<p className='text-danger mb-0'>{errors ? errors.entidad_financiera?.message : ''}</p>)}
                </div>
                <div className="col-12 col-md-3 col-lg-3 col-xl-4">
                    <label>{t('account_type')}</label>  
                    <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                        const currentSelection = tcuenta && tcuenta.find((c) => c.value === value )
                        const selectChange = (selected) => onChange(selected.value) 
                        return (
                            <Select
                            label=""
                            isDisabled={disabled}
                            instaceId="selectbox"
                            ref={ref}
                            value={currentSelection}
                            name={name}
                            options={tcuenta}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0
                            })}
                            className='react-select-container'
                            onChange={selectChange}
                            placeholder={`${t('select')}`}
                            />
                        )
                        }}
                        name="tipo_cuenta" 
                    />
                    {errors.tipo_cuenta && (<p className='text-danger mb-0'>{errors ? errors.tipo_cuenta?.message : ''}</p>)}
                    
                </div>
                <div className="col-12 col-md-9 col-lg-9 col-xl-8">
                    <label>{t('account_number')}</label>  
                    <input type="text" className='form-control' {...register("numero_cuenta")} disabled={disabled}/>
                    {errors.numero_cuenta && (<p className='text-danger mb-0'>{errors ? errors.numero_cuenta?.message : ''}</p>)}
                </div>            
            </div>
            <div className="row py-1">
                <h5 className='mt-2 mb-0'>{t('financial_contact')}</h5>
                <hr className="col-11 ml-2 my-1 py-0"/>
                <div className="col-12 col-md-6">
                    <label>{t('name')}</label>  
                    <input type="text" className='form-control' {...register("nombre_contacto_financiero")} disabled={disabled}/>
                    {errors.nombre_contacto_financiero && (<p className='text-danger mb-0'>{errors ? errors.nombre_contacto_financiero?.message : ''}</p>)}
                </div>
                <div className="col-12 col-md-6">
                    <label>{t('post')}</label>  
                    <input type="text" className='form-control' {...register("cargo_contacto_financiero")} disabled={disabled}/>
                    {
                        errors.cargo_contacto_financiero && (
                        <p className='text-danger mb-0'>{errors ? errors.cargo_contacto_financiero?.message : ''}</p>
                        )
                    }
                </div>
                <div className="col-12 col-md-6">
                    <label>{t('tel_no')}</label>  
                    <input type="number" className='form-control' {...register("telefono_contacto_financiero")} disabled={disabled}/>
                    { 
                        errors.telefono_contacto_financiero && (
                        <p className='text-danger mb-0'>{errors ? errors.telefono_contacto_financiero?.message : ''}</p>
                        )
                    }
                </div>                
                <div className="col-12 col-md-6">
                    <label>{t('email')}</label>  
                    <input type="text" className='form-control' {...register("correo_electronico_financiero")} disabled={disabled}/>
                    {errors.correo_electronico_financiero && (<p className='text-danger mb-0'>{errors ? errors.correo_electronico_financiero?.message : ''}</p>)}
                </div>
            </div>
            <div className="row py-1">
            <h5 className='mt-2 mb-0'>{t('commercial_contact')}</h5>
            <hr className="col-11 ml-2 my-1 py-0"/>
            {/* nuevos campos */}
            <div className="col-12 col-md-6">
                <label>{t('name')}</label>  
                <input type="text" className='form-control' {...register("nombre_contacto_comercial")} disabled={disabled}/>
                {errors.nombre_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.nombre_contacto_comercial?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('post')}</label>  
                <input type="text" className='form-control' {...register("cargo_contacto_comercial")} disabled={disabled}/>
                {errors.cargo_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.cargo_contacto_comercial?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('tel_no')}</label>  
                <input type="number" className='form-control' {...register("telefono_contacto_comercial")} disabled={disabled}/>
                {errors.telefono_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.telefono_contacto_comercial?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('email')}</label>  
                <input type="text" className='form-control' {...register("correo_electronico_contacto_comercial")} disabled={disabled}/>
                {
                errors.correo_electronico_contacto_comercial 
                && (
                    <p className='text-danger mb-0'>{errors ? errors.correo_electronico_contacto_comercial?.message : ''}</p>
                )
                }
            </div>
            <div className='col-12 col-md-12'>
                <label>{t('explanation_services_offered')}</label>   
                <textarea 
                rows="3"
                className='form-control' 
                {...register("expbienes_servicios")} 
                disabled={disabled}
                ></textarea>
                {errors.expbienes_servicios && (<p className='text-danger mb-0'>{errors ? errors.expbienes_servicios?.message : ''}</p>)}
            </div>
            </div>
            <div className="row py-1">
            <h5 className='mt-2 mb-0'>{t('contact_lg')}</h5>
            <hr className="col-11 ml-2 my-1 py-0"/>
            {/* nuevos campos */}
            <div className="col-12 col-md-6">
                <label>{t('name')}</label>  
                <input type="text" className='form-control' {...register("nombre_contacto_lg")} disabled={disabled}/>
                {errors.nombre_contacto_lg && (<p className='text-danger mb-0'>{errors ? errors.nombre_contacto_lg?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('post')}</label>  
                <input type="text" className='form-control' {...register("cargo_contacto_lg")} disabled={disabled}/>
                {errors.cargo_contacto_lg && (<p className='text-danger mb-0'>{errors ? errors.cargo_contacto_lg?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('tel_no')}</label>    
                <input type="number" className='form-control' {...register("telefono_contacto_lg")} disabled={disabled}/>
                {errors.telefono_contacto_lg && (<p className='text-danger mb-0'>{errors ? errors.telefono_contacto_lg?.message : ''}</p>)}
            </div>
            <div className="col-12 col-md-6">
                <label>{t('email')}</label>  
                <input type="text" className='form-control' {...register("correo_electronico_contacto_lg")} disabled={disabled}/>
                {
                    errors.correo_electronico_contacto_lg 
                    && (
                    <p className='text-danger mb-0'>{errors ? errors.correo_electronico_contacto_lg?.message : ''}</p>
                    )
                }
            </div>
            </div>        
            { form?.iddir?.length > 0
                ? (
                    <div className="row py-1">
                        <h5 className='mt-2 mb-0'>
                            {t('upload')}
                        </h5>
                        <hr className="col-11 ml-2 my-1 py-0"/>                    
                        {
                            form?.iddir.map(r => (
                                <div className="col-12 col-md-6 py-2" key={r.name_dir}>
                                    <div>
                                        { r.name_dir === 'Formato Jeong do Management'
                                            ? (
                                                <>
                                                    <label>{r.name_dir}
                                                    <span className='text-red mr-2'>{r.requerid === 'S' ? '*' : ''}</span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <Link to={`${process.env.REACT_APP_ENDPOINT}upload/jeong_do_management.pdf`} target="_blank">
                                                        &nbsp;{t('download_dojeong')}&nbsp;
                                                        </Link>
                                                    }> 
                                                        <i 
                                                        className="fas fa-download text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ) : r.name_dir === 'Camara de Comercio'
                                            ? (
                                                <>
                                                    <label>{r.name_dir} - { t('less_days') }
                                                        <span className='text-red mr-2'>
                                                            {r.requerid === 'S' ? '*' : ''}
                                                        </span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <p className='mb-0 text-justify'>
                                                            {t('less_days')} 
                                                        </p>
                                                    }> 
                                                        <i 
                                                        className="fas fa-exclamation-circle text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ) : r.name_dir === 'Extracto Cuenta Bancaria'
                                            ? (
                                                <>
                                                    <label>{r.name_dir}
                                                    <span className='text-red mr-2'>{r.requerid === 'S' ? '*' : ''}</span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <p className='mb-0 text-justify'>
                                                            {t('first_page')} 
                                                        </p>
                                                    }> 
                                                        <i 
                                                        className="fas fa-exclamation-circle text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ) : r.name_dir === 'RUT'
                                            ? (
                                                <>
                                                    <label>{r.name_dir}
                                                    <span className='text-red mr-2'>{r.requerid === 'S' ? '*' : ''}</span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <p className='mb-0 text-justify'> 
                                                        {t('vigency')}
                                                        </p>
                                                    }> 
                                                        <i 
                                                        className="fas fa-exclamation-circle text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ) : r.name_dir === 'Estados Financieros'
                                            ? (
                                                <>
                                                    <label>{r.name_dir}
                                                    <span className='text-red mr-2'>{r.requerid === 'S' ? '*' : ''}</span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <p className='mb-0 text-justify'>
                                                        {t('financial_comment')}
                                                        </p>
                                                    }> 
                                                        <i 
                                                        className="fas fa-exclamation-circle text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ) : r.name_dir === 'ID Representante Legal'
                                            ? (
                                                <>
                                                    <label>{r.name_dir}
                                                    <span className='text-red mr-2'>{r.requerid === 'S' ? '*' : ''}</span>
                                                    </label>
                                                    <PopoverCustom content={
                                                        <p className='mb-0 text-justify'>{t('cedci')}</p>
                                                    }> 
                                                        <i 
                                                        className="fas fa-exclamation-circle text-gray"
                                                        role="button" tabIndex="0"
                                                        ></i> 
                                                    </PopoverCustom>
                                                </>
                                            ): (
                                                <label>{r.name_dir}
                                                <span className='text-red'>{r.requerid === 'S' ? '*' : ''}</span>
                                                </label>
                                            )
                                        }
                                    </div>
                                    <div className="col-12 col-md-12 px-0 py-0">
                                        <Controller
                                            name={`iddir_${r.iddir}`}
                                            control={control}
                                            rules={{ 
                                                required: r.requerid === 'S' ? true : false,
                                            }}
                                            render={({ field: { onChange, ref } }) => (
                                                <>
                                                    <input
                                                    type="file" 
                                                    id={`iddir_${r.iddir}`}
                                                    accept="application/pdf"
                                                    className='form-control'
                                                    onChange={ (event) => { 
                                                        onChange(event.target.files[0])
                                                        handleFileChange(event, r) 
                                                    } }
                                                    ref={ref}
                                                    />
                                                    { errors[`iddir_${r.iddir}`] && (
                                                    <p className='text-danger mb-0'>{ errors[`iddir_${r.iddir}`].message }</p>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
                : ''
            }
            <div className='py-2'>
            <div className='row'>
                <div className='col-12 d-flex flex-row gap-3 ml-2'>
                <Controller
                    control={control}
                    name="check_habeas_data"
                    render={({ field: { onChange, value, name}}) => (
                    <Checkbox
                        name={name} 
                        ref={check_habeas_data}
                        value={value}
                        onChange={(e) => {onChange(e)}}
                        className="form-check"
                        disabled={disabled}
                    />
                    )}
                />
                <button 
                    type='button' 
                    className='text-decoration-none text-black btn btn-link m-0 p-0' 
                    onClick={modalShow}
                >
                    {t('authorization_personal_data')}
                </button>
                </div>
                <div className="col-12">
                {errors.check_habeas_data && 
                    (<p className='text-danger mb-0'>
                    {errors ? errors.check_habeas_data?.message : ''}
                    </p>)
                }
                </div>
            </div>
            </div>
            
            <div className="row py-1">
                <div className='col-12 col-sm-4 col-md-4 my-2'>
                    <button type="button" className='btn btn-secondary btn-flat float-left' onClick={() => navigate('/login')}>{t('back')}</button>
                </div>
                <div className='col-12 col-sm-8 col-md-8 my-2'>
                    
                    { 
                        form?.iddir?.length > 0
                        ? (
                            <button 
                            type="button" 
                            className='btn btn-danger btn-flat float-right ml-1 text-uppercase' 
                            onClick={handleSubmit(onSubmit)} 
                            disabled={disabled}
                            >
                            {t('save')}
                            </button>
                        ) : ''
                    }
                    <button  
                        type="button" 
                        className='btn btn-secondary btn-flat float-right ml-1 mr-1 text-uppercase' 
                        onClick={onTemp} 
                        disabled={disabled}
                        >
                        {t('temp')}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-secondary btn-flat float-right mr-1'
                        onClick={handleSubmit(onPreview)}
                        disabled={disabled}
                    >
                    PDF
                    </button>
                </div>
            </div>
        </form>
        </>
    )
}
