import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm} from "react-hook-form";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//thunks
import { ActualizarForm} from "../../slices/vendor/vendorSlice";
import {
  sendFormupd,
} from "../../slices/vendor/vendorThunks";


export const Updvendorextranjero = () => {
  const dispatch = useDispatch();
  //traduccion
  const { t } = useTranslation();

  //data formulario
  const {
    form,
    disabled
  } = useSelector((state) => state.form_vendor);

  //form
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });
  
  //send form
  const onSubmit = async (values) => {
    values.draft='N';
    values.correo_empresarial = form.correo_electronico_contacto_comercial;
    values.pais = "CO";
    values.telefono_celular = "313524";
    values.departamento = 11;
    values.ciudad = 11001;
    values.ciudad_nombre = "BOGOTÁ";

    dispatch(ActualizarForm(values));
    Swal.fire({
      title: t("sure"),
      text: t("suretext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("sureyesbutton"),
      cancelButtonText: t("surecancelbutton"),
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(sendFormupd());     
        } catch (error) {
          toast.error(error);
        }
      }
    });
  };
    
  //reset values redux
  useEffect(() => {
    if (form.numero_documento !== 0) {
      reset({
        //form 1
        numero_documento: form?.numero_documento,
        tipo_compania: form?.tipo_compania,
        tipo_documento: form?.tipo_documento,
        razon_social: form?.razon_social,
        direccion_principal: form?.direccion_principal,
        codigo_postal: form?.codigo_postal,
        telefono_fijo: form?.telefono_fijo,
        telefono_celular: form?.telefono_celular,
        correo_empresarial: form?.correo_empresarial,
        pais: form?.pais,
        departamento: form?.departamento,
        ciudad: form?.ciudad,
        ciudad_nombre: form?.ciudad_nombre,
        matricula_mercantil: form?.matricula_mercantil,
        sagrilaft: form?.sagrilaft,
        sarlaft: form?.sarlaft,
        prevension_lavados_activos: form?.prevension_lavados_activos,
        //form 2
        regimen_tributario: form?.regimen_tributario,
        responsable_iva: form?.responsable_iva,
        //form 3
        nombre_rep: form?.nombre_rep,
        tipo_documento_rep: form?.tipo_documento_rep,
        numero_de_identidad_rep: form?.numero_de_identidad_rep,
        correo_electronico_rep: form?.correo_electronico_rep,
        numero_telefonico_rep: form?.numero_telefonico_rep,
        persona_expuesta: form?.persona_expuesta,
        //form 4
        tamano_empresa: form?.tamano_empresa,
        entidad_financiera: form?.entidad_financiera,
        numero_cuenta: form?.numero_cuenta,
        tipo_cuenta: form?.tipo_cuenta,
        //form 5
        nombre_contacto_financiero: form?.nombre_contacto_financiero,
        cargo_contacto_financiero: form?.cargo_contacto_financiero,
        telefono_contacto_financiero: form?.telefono_contacto_financiero,
        correo_electronico_financiero: form?.correo_electronico_financiero,
        //form 6
        nombre_contacto_comercial: form?.nombre_contacto_comercial,
        cargo_contacto_comercial: form?.cargo_contacto_comercial,
        telefono_contacto_comercial: form?.telefono_contacto_comercial,
        correo_electronico_contacto_comercial:
          form?.correo_electronico_contacto_comercial,
        expbienes_servicios: form?.expbienes_servicios,
        //form 7
        nombre_contacto_lg: form?.nombre_contacto_lg,
        cargo_contacto_lg: form?.cargo_contacto_lg,
        telefono_contacto_lg: form?.telefono_contacto_lg,
        correo_electronico_contacto_lg: form?.correo_electronico_contacto_lg,
        check_habeas_data: form?.check_habeas_data,
        //exterior
        bussines_type: form?.bussines_type,
        contact_person_requestor: form?.contact_person_requestor,
        office_tel_requestor: form?.office_tel_requestor,
        email_requestor: form?.email_requestor,
        account_name_bankinfo: form?.account_name_bankinfo, 
        bank_name_bankinfo: form?.bank_name_bankinfo,
        bank_branch_name_bankinfo: form?.bank_branch_name_bankinfo,
        bank_branch_aba_bankinfo: form?.bank_branch_aba_bankinfo,
        currency_bankinfo: form?.currency_bankinfo,
        swift_code_bankinfo: form?.swift_code_bankinfo,
        bank_address_bankinfo: form?.bank_address_bankinfo
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, form?.numero_documento]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
      <div className="row py-1">
        <div className="col-12">
          <h5 className="mt-2 mb-0">{t("general_supplier_information")}</h5>
          <hr className="col-12 ml-0 my-1 px-0 py-0" />
        </div>

        <div className='col-12 col-md-6 '>
          <label>{t('name_or_company_name')}</label>  
          <input type="text" className='form-control'  {...register("razon_social")} disabled={disabled}/>
          {errors.razon_social && (<p className='text-danger mb-0'>{errors ? errors.razon_social?.message : ''}</p>)}
        </div>
        <div className='col-12 col-md-6'>
          <label>{t('primary_home_address')}</label>  
          <input type="text" className='form-control' {...register("direccion_principal") } disabled={disabled}/>
          {errors.direccion_principal && (<p className='text-danger mb-0'>{errors ? errors.direccion_principal?.message : ''}</p>)}
        </div>
        <div className='col-12 col-md-6'>
          <label>{t('bussinest')}</label>  
          <input type="text" className='form-control' {...register("bussines_type") } disabled={disabled}/>
          {errors.bussines_type && (<p className='text-danger mb-0'>{errors ? errors.bussines_type?.message : ''}</p>)}
        </div>
        <div className='col-12 col-md-6'>
          <label>{t('commercial_date')}</label>   
          <input type="date" className='form-control' {...register("matricula_mercantil")} disabled={disabled}/>
          {errors.matricula_mercantil && (<p className='text-danger mb-0'>{errors ? errors.matricula_mercantil?.message : ''}</p>)}
        </div>
      </div>
      <div className="row py-1">
        <h5 className='mt-2 mb-0'>{t('requesdep')}</h5>
        <hr className="col-11 ml-2 my-1 py-0"/>
        {/* nuevos campos */}
        <div className="col-12 col-md-6">
          <label>{t('contacp')}</label>  
          <input type="text" className='form-control' {...register("contact_person_requestor")} disabled={disabled}/>
          {errors.contact_person_requestor && (<p className='text-danger mb-0'>{errors ? errors.contact_person_requestor?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('officetel')}</label>    
          <input type="number" className='form-control' {...register("office_tel_requestor")} disabled={disabled}/>
          {errors.office_tel_requestor && (<p className='text-danger mb-0'>{errors ? errors.office_tel_requestor?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('emailc')}</label>  
          <input type="text" className='form-control' {...register("email_requestor")} disabled={disabled}/>
          {errors.email_requestor && (<p className='text-danger mb-0'>{errors ? errors.email_requestor?.message : ''}</p>)}
        </div>
      </div>
      <div className="row py-1">
        <h5 className='mt-2 mb-0'>{t('finacedep')}</h5>
        <hr className="col-11 ml-2 my-1 py-0"/>
        {/* nuevos campos */}
        <div className="col-12 col-md-6">
          <label>{t('contacp')}</label>  
          <input type="text" className='form-control' {...register("nombre_contacto_comercial")} disabled={disabled}/>
          {errors.nombre_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.nombre_contacto_comercial?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('officetel')}</label>    
          <input type="number" className='form-control' {...register("telefono_contacto_comercial")} disabled={disabled}/>
          {errors.telefono_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.telefono_contacto_comercial?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('emailc')}</label>  
          <input type="text" className='form-control' {...register("correo_electronico_contacto_comercial")} disabled={disabled}/>
          {errors.correo_electronico_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.correo_electronico_contacto_comercial?.message : ''}</p>)}
        </div>
      </div>
      <div className="row py-1">
        <h5 className='mt-2 mb-0'>{t('representative')}</h5>
        <hr className="col-11 ml-2 my-1 py-0"/>
        {/* nuevos campos */}
        <div className="col-12 col-md-6">
          <label>{t('contacp')}</label>  
          <input type="text" className='form-control' {...register("nombre_rep")} disabled={disabled}/>
          {errors.nombre_rep && (<p className='text-danger mb-0'>{errors ? errors.nombre_rep?.message : ''}</p>)}
        </div>
      </div> 
      <div className="row py-1">
        <h5 className='mt-2 mb-0'>{t('bankinf')}</h5>
        <hr className="col-11 ml-2 my-1 py-0"/>
        {/* nuevos campos */}
        <div className="col-12 col-md-6">
          <label>{t('accname')}</label>  
          <input type="text" className='form-control' {...register("account_name_bankinfo")} disabled={disabled}/>
          {errors.account_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.account_name_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('bankn')}</label>  
          <input type="text" className='form-control' {...register("bank_name_bankinfo")} disabled={disabled}/>
          {errors.bank_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_name_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('bankbn')}</label>  
          <input type="text" className='form-control' {...register("bank_branch_name_bankinfo")} disabled={disabled}/>
          {errors.bank_branch_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_branch_name_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('bankbar')}</label>  
          <input type="text" className='form-control' {...register("bank_branch_aba_bankinfo")} disabled={disabled}/>
          {errors.bank_branch_aba_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_branch_aba_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('bankaccn')}</label>  
          <input type="text" className='form-control' {...register("numero_cuenta")} disabled={disabled}/>
          {errors.numero_cuenta && (<p className='text-danger mb-0'>{errors ? errors.numero_cuenta?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('currency')}</label>  
          <input type="text" className='form-control' {...register("currency_bankinfo")} disabled={disabled}/>
          {errors.currency_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.currency_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('scode')}</label>  
          <input type="text" className='form-control' {...register("swift_code_bankinfo")} disabled={disabled}/>
          {errors.swift_code_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.swift_code_bankinfo?.message : ''}</p>)}
        </div>
        <div className="col-12 col-md-6">
          <label>{t('banka')}</label>  
          <input type="text" className='form-control' {...register("bank_address_bankinfo")} disabled={disabled}/>
          {errors.bank_address_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_address_bankinfo?.message : ''}</p>)}
        </div>
      </div> 

      <div className="row py-1">
          <div className="col-12  my-2">
            <button
              type="button"
              className="btn btn-danger btn-flat float-right ml-1  col-2 text-uppercase"
              onClick={handleSubmit(onSubmit)}
            >
              {t("save")}
            </button>
          </div>
        </div>   
    </form>
  );
};
