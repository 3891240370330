import React from 'react';
//import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
// import chevronDown from "./chevron-down.svg";
// import styles from "./styles.module.css";
import { Filtros } from '../../../filtros';  

export const AccordeonFilters = () => {
  return (
    <div  >
      <div className="card-body">
        <div id="accordion">
        <div className="card card-light">
          <div className="card-header">
            <h4 className="card-title w-100">
              <a className="d-block w-100" data-toggle="collapse" href="#collapseOne">
                Filtros
              </a>
            </h4>
          </div>
          <div id="collapseOne" className="collapse show" data-parent="#accordion">
            <div className="card-body">
              <Filtros/>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};



  






