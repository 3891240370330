import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
//thunks
import { 
  getBanks, getCiudad, getCodigoPostal,
  getDepartamento, getDocumentType, 
  getEfectosTributarios, getEfectosTributariosTarifa, 
  getGlosarioTipago, getGlosarioTipoEmpresa,
  getPais, getRegimen,
  getRegimentributario, getRequired,
  getTypedocument, validateNit
} from "../../slices/vendor/vendorThunks";
//form by origin
import { Nacional } from '../form/Nacional';
import { Extranjero } from '../form/Extranjero';
//css
import '../../css/form.css';
//loading
const LoadingPortal = lazy(() => import ('../loadings/LoadingPortal'));

export const Register = () => { 
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const { form, disablednit, loading } = useSelector((state) => state.form_vendor);
  //change language
  const changeLanguage = (lng) => { i18n.changeLanguage(lng); };
  //Validate NIT
  const { 
    register,
    formState: { errors },
    watch
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: "onSubmit"
  });

  //search vendor
  const handleNit = async () => {
    const watchNit = watch("numero_documento");
    dispatch(validateNit(watchNit))
  }

  //get data
  useEffect(() => {
    dispatch(getDocumentType());
    dispatch(getCodigoPostal());
    dispatch(getPais());
    dispatch(getDepartamento());
    dispatch(getCiudad());
    dispatch(getEfectosTributarios());
    dispatch(getEfectosTributariosTarifa());
    dispatch(getRegimen());
    dispatch(getRegimentributario());
    dispatch(getGlosarioTipoEmpresa());
    dispatch(getGlosarioTipago());
    dispatch(getTypedocument());
    dispatch(getBanks());
    dispatch(getRequired());
    //dispatch(setLoading(true))
  }, [dispatch]);

  
  return (
    <Suspense fallback={<div></div>}>
      <div className='background'>
        { loading ? (<LoadingPortal isOpen={loading} />): ''}
        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-light shadow-sm px-3 justify-content-between">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="navbar-brand">
            <img src={`${process.env.REACT_APP_HOST}/dist/img/logo-lg.svg`} width="80"  alt="" />
          </div>

          <div className='d-flex align-items-center justify-content-between'>
            <div className='mr-2'>{t('lang')}</div>
            <button onClick={() => changeLanguage('en')} className="btn btn-light p-0">
              <img 
                src={`${process.env.REACT_APP_HOST}/dist/img/usaflag.png`}  
                width='30' alt="english" 
              />
            </button>
            <button onClick={() => changeLanguage('es')} className="btn btn-light p-0">
              <img 
                src={`${process.env.REACT_APP_HOST}/dist/img/esflag.png`}  
                width='30' alt="spanish" 
              />
            </button>
          </div>

        </nav>

        <div className="container mx-auto py-5" id='printform'>    
          <div className="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1 mt-4">
            <div className="card border rounded">
              <div className="row px-3 py-1">
                <div className='col-12 text-center'>
                    <h3 className='mt-3 mb-0 pb-0'>{ t('vrrf') }</h3>
                    <hr className="col-md-11 mt-1 px-1"/>
                </div> 
                <div className="col-12 col-sm-6 col-md-5 mb-3">
                  <label>{ t('document_no') }</label> 
                  <div className="input-group input-group-md">
                    <input type="text" 
                      className={errors.numero_documento ? 'border-danger form-control' : 'form-control'} 
                      {...register("numero_documento")} 
                      disabled={disablednit}
                    />
                    <span className="input-group-append">
                      <button 
                        type="button" 
                        className="btn btn-danger btn-flat"
                        onClick={handleNit}
                        disabled={disablednit}
                      >
                        <i className='fas fa-search'></i> 
                      </button>
                    </span>
                  </div>
                  {errors.numero_documento && (<p className='text-danger mb-0'>{errors.numero_documento?.message}</p>)}
                </div>
                {
                  form.origen === 'N' 
                  ? (
                    <Nacional />
                  ) : (
                    ''
                  )
                } 
                {
                  form.origen === 'E' 
                  ? (
                    <Extranjero />
                  ) : (
                    ''
                  )
                } 
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}