import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {ChannelPSI} from '../pages/channelPSI'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'

export const BiRoutes = () => {
  
  const HomeBiParams = () => {
    return (
      <p>403 con params</p>
    )
  }

  return (
    <Routes>
      <Route path="bi" errorElement={<ErrorBoundary />}>
        <Route path='cpsi' element={<ChannelPSI />} />
        <Route path=':params' element={<HomeBiParams />} />
      </Route>    
    </Routes>
  )
}
