import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getBanks, getCiudad, getCodigoPostal,
    getData,
    getDepartamento, getDocumentType, 
    getEfectosTributarios, getEfectosTributariosTarifa, 
    getGlosarioTipago, getGlosarioTipoEmpresa,
    getPais, getRegimen,
    getRegimentributario, getRequired,
    getTypedocument
} from '../../../slices/vendor/vendorThunks';

//css
import '../../../css/form.css';
import { Updvendornacional } from '../../form/Updvendornacional';
import { Updvendorextranjero } from '../../form/Updvendorextranjero';
//loading
const LoadingPortal = lazy(() => import ('../../loadings/LoadingPortal'));




const Updatedata = () => {
    const dispatch = useDispatch();
    const { form, loading } = useSelector((state) => state.form_vendor);
    const { perfil } = useSelector((state) => state.user);

    useEffect(() => {
        if (perfil.identification !== null) {            
            dispatch(getData(perfil.identification))
        }
    }, [dispatch, perfil])
    

     //get data
      useEffect(() => {
        dispatch(getDocumentType());
        dispatch(getCodigoPostal());
        dispatch(getPais());
        dispatch(getDepartamento());
        dispatch(getCiudad());
        dispatch(getEfectosTributarios());
        dispatch(getEfectosTributariosTarifa());
        dispatch(getRegimen());
        dispatch(getRegimentributario());
        dispatch(getGlosarioTipoEmpresa());
        dispatch(getGlosarioTipago());
        dispatch(getTypedocument());
        dispatch(getBanks());
        dispatch(getRequired());
    }, [dispatch]);

    return (
        <Suspense fallback={<div></div>}>
            { loading ? (<LoadingPortal isOpen={loading} />): ''}
            <div className='row'>
                <div className='col-12'>
                    <div className="row my-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Actualización de datos </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">Proveedores</li>
                            <li className="breadcrumb-item active">Actualización de datos</li>
                            </ol>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="card border rounded">
                                <div className="row px-3 py-1">
                                {
                                    form.origen === 'N' 
                                    ? (
                                        <Updvendornacional />
                                    ) : (
                                        <Updvendorextranjero />
                                    )
                                } 
                                {/* <p>{JSON.stringify(form)}</p>   */} 
                                </div>
                            </div>
                        </div>
                               
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export default Updatedata