import * as yup from 'yup';

// Add method
yup.addMethod(yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? '' : value));
});

const SUPPORTED_FORMATS = [
    "application/pdf",
];

export const generateValidationSchema = (fileFields) => {
    const schema = {
        numero_documento: yup.string().stripEmptyString().required('required'),
        razon_social: yup.string().trim().required('required'),
        direccion_principal: yup.string().trim().required('required'),
        bussines_type: yup.string().trim().required('required'),
        matricula_mercantil:  yup.string().trim().required('required'),
        contact_person_requestor: yup.string().trim().required('required'),
        office_tel_requestor: yup.string().trim().required('required'),
        email_requestor: yup.string().email('Email is not valid').required('required'),
        nombre_contacto_comercial: yup.string().trim().required('required'),
        telefono_contacto_comercial: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        correo_electronico_contacto_comercial: yup.string().email('Email is not valid').required('required'),
        nombre_rep: yup.string().trim().required('required'),
        account_name_bankinfo: yup.string().trim().required('required'),
        bank_name_bankinfo:yup.string().trim().required('required'),
        bank_branch_name_bankinfo: yup.string().trim().required('required'),
        bank_branch_aba_bankinfo: yup.string().trim().required('required'),
        numero_cuenta: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        currency_bankinfo: yup.string().trim().required('required'),
        swift_code_bankinfo: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        bank_address_bankinfo: yup.string().trim().required('required'),
        check_habeas_data: yup.boolean().required('required').oneOf([true], 'required'),
        ...fileFields.reduce((acc, field) => {
            acc[`iddir_${field.iddir}`] = yup.mixed()
            .test("required", "required", (file) => {
                if (file) return true;
                return false;
            }).test(
                "fileFormat",
                "Only the following formats are accepted:  .pdf",
                value => value && SUPPORTED_FORMATS.includes(value.type)
              );
            return acc;
        }, {}),
        
    }

    return yup.object(schema);
}