import { instanceAxios, instanceAxiosPrivate } from "../../axios";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import i18next from 'i18next';

import {
    ActualizarForm,
    setPaisDane,
    setDepartamentoDane,
    setCiudadDane,
    setDocumentType,
    setEfectosTributarios,
    setGlosarioTipoEmpresa,
    setEfectosTributariosTarifa,
    setGlosarioTipago,
    setCodigoPostal,
    setRegimen,
    setTypedocument,
    updArrayVal,
    setDisabled,
    setFilesUpd,
    updArrayfilesVal,
    setRegimentrib,
    setDisabledNit,
    setPDF,
    setLoading,
    setBanks,
    setRequired
} from './vendorSlice';

export const validateNit = (e) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/vendorprocess`, {
                params: {
                    numero_documento: e
                }
            });        

            if(data && data?.draft !== 'S'){                
                let info = {
                    origen: data.origen,
                    //form 1
                    numero_documento: data.nit,
                    tipo_compania: data.id_person_type || null,
                    tipo_documento: data.id_document_type || null,
                    razon_social: data.name || null,
                    direccion_principal: data.address || null,
                    codigo_postal: Number(data.codigo_postal) || null,
                    telefono_fijo: null,
                    telefono_celular: null,
                    correo_empresarial: data.mail_address || null,
                    pais: data.codigo_pais,
                    departamento:  Number(data.codigo_departamento_dane) || null,
                    ciudad:  Number(data.codigo_ciudad_dane) || null,
                    ciudad_nombre: data.city || null,
                    matricula_mercantil: null,
                    sagrilaft: null,
                    sarlaft: null,
                    prevension_lavados_activos: null,
                    //form 2
                    regimen_tributario: null,
                    responsable_iva: null,
                    //form 3
                    nombre_rep: "",
                    tipo_documento_rep: "",
                    numero_de_identidad_rep: "",
                    correo_electronico_rep: "",
                    numero_telefonico_rep: null,
                    persona_expuesta: "",
                    //form 4
                    tamano_empresa: "",
                    entidad_financiera: Number(data.bank_name) || null,
                    numero_cuenta: data.bank_account_type || null,
                    tipo_cuenta: "",
                    //form 5
                    nombre_contacto_financiero: "",
                    cargo_contacto_financiero: "",
                    telefono_contacto_financiero: "",
                    correo_electronico_financiero: "",
                    //form 6
                    nombre_contacto_comercial: data.nom_contacto || null,
                    cargo_contacto_comercial: "",
                    telefono_contacto_comercial: "",
                    correo_electronico_contacto_comercial: data.commercial_email.replace(',', '') || null,
                    expbienes_servicios: "",
                    //form 7
                    nombre_contacto_lg: "",
                    cargo_contacto_lg: "",
                    telefono_contacto_lg: "",
                    correo_electronico_contacto_lg: "",
                    //exterior
                    bussines_type: "",
                    contact_person_requestor: "",
                    office_tel_requestor: "",
                    email_requestor: "",
                    account_name_bankinfo: "", 
                    bank_name_bankinfo: "",
                    bank_branch_name_bankinfo: "",
                    bank_branch_aba_bankinfo: "",
                    currency_bankinfo: "",
                    swift_code_bankinfo: "",
                    bank_address_bankinfo: "",
                    //form documentos
                    iddir: data.iddir || [],
                    //habeas data
                    checkHabeasData: false,
                    
                    user_id: data.user_id,
                    name_dir: data.name_dir || "",
                    idparent: data.idparent || ""
                }
                dispatch(ActualizarForm(info));
                dispatch(setDisabled(false));
                dispatch(setDisabledNit(true));
            } else if( data?.draft === 'S') {
                let transformdata = JSON.parse(data?.json_vendor.replace("'", "").replace("'", ""));
                const showConfirmDialog = () => {
                    Swal.fire({
                        title: i18next.t('titletemp'),
                        text: i18next.t('texttemp'),
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: i18next.t('sureyestempbutton'),
                        cancelButtonText: i18next.t('surecancelbutton')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let info = {
                                origen: transformdata.origen,
                                //form 1
                                numero_documento: transformdata.numero_documento,
                                tipo_compania: Number(transformdata.tipo_compania),
                                tipo_documento: Number(transformdata.tipo_documento),
                                razon_social: transformdata.razon_social,
                                direccion_principal: transformdata.direccion_principal,
                                codigo_postal: Number(transformdata.codigo_postal),
                                telefono_fijo: transformdata.telefono_fijo,
                                telefono_celular: transformdata.telefono_celular,
                                correo_empresarial: transformdata.correo_empresarial,
                                pais: transformdata.pais,
                                departamento: Number(transformdata.departamento),
                                ciudad: Number(transformdata.ciudad),
                                ciudad_nombre: transformdata.ciudad_nombre,
                                matricula_mercantil: transformdata.matricula_mercantil,
                                sagrilaft: Number(transformdata.sagrilaft),
                                sarlaft: Number(transformdata.sarlaft),
                                prevension_lavados_activos: Number(transformdata.prevension_lavados_activos),
                                //form 2
                                regimen_tributario: Number(transformdata.regimen_tributario),
                                responsable_iva: Number(transformdata.responsable_iva),
                                //form 3
                                nombre_rep: transformdata.nombre_rep,
                                tipo_documento_rep: Number(transformdata.tipo_documento_rep),
                                numero_de_identidad_rep: transformdata.numero_de_identidad_rep,
                                correo_electronico_rep: transformdata.correo_electronico_rep,
                                numero_telefonico_rep: transformdata.numero_telefonico_rep,
                                persona_expuesta: Number(transformdata.persona_expuesta),
                                //form 4
                                tamano_empresa: Number(transformdata.tamano_empresa),
                                entidad_financiera: Number(transformdata.entidad_financiera),
                                numero_cuenta: transformdata.numero_cuenta,
                                tipo_cuenta: Number(transformdata.tipo_cuenta),
                                //form 5
                                nombre_contacto_financiero: transformdata.nombre_contacto_financiero,
                                cargo_contacto_financiero: transformdata.cargo_contacto_financiero,
                                telefono_contacto_financiero: transformdata.telefono_contacto_financiero,
                                correo_electronico_financiero: transformdata.correo_electronico_financiero,
                                //form 6
                                nombre_contacto_comercial: transformdata.nombre_contacto_comercial,
                                cargo_contacto_comercial: transformdata.cargo_contacto_comercial,
                                telefono_contacto_comercial: transformdata.telefono_contacto_comercial,
                                correo_electronico_contacto_comercial: transformdata.correo_electronico_contacto_comercial,
                                expbienes_servicios: transformdata.expbienes_servicios,
                                //form 7
                                nombre_contacto_lg: transformdata.nombre_contacto_lg,
                                cargo_contacto_lg: transformdata.cargo_contacto_lg,
                                telefono_contacto_lg: transformdata.telefono_contacto_lg,
                                correo_electronico_contacto_lg: transformdata.correo_electronico_contacto_lg,
                                //exterior
                                bussines_type: transformdata.bussines_type,
                                contact_person_requestor: transformdata.contact_person_requestor,
                                office_tel_requestor: transformdata.office_tel_requestor,
                                email_requestor: transformdata.email_requestor,
                                account_name_bankinfo: transformdata.account_name_bankinfo, 
                                bank_name_bankinfo: transformdata.bank_name_bankinfo,
                                bank_branch_name_bankinfo: transformdata.bank_branch_name_bankinfo,
                                bank_branch_aba_bankinfo: transformdata.bank_branch_aba_bankinfo,
                                currency_bankinfo: transformdata.currency_bankinfo,
                                swift_code_bankinfo: transformdata.swift_code_bankinfo,
                                bank_address_bankinfo: transformdata.bank_address_bankinfo,
                                //form documentos
                                iddir: transformdata.iddir || [],
                                //habeas data
                                checkHabeasData: transformdata.checkHabeasData || false,
                
                                user_id: transformdata.user_id,
                                name_dir: transformdata.name_dir || "",
                                idparent: transformdata.idparent || ""
                            }
                            dispatch(ActualizarForm(info));
                            dispatch(setDisabled(false));
                            dispatch(setDisabledNit(true));
                        } else {
                            let info = {
                                origen: data.origen,
                                //form 1
                                numero_documento: data.nit,
                                tipo_compania: data.id_person_type || null,
                                tipo_documento: data.id_document_type || null,
                                razon_social: data.name || null,
                                direccion_principal: data.address || null,
                                codigo_postal: Number(data.codigo_postal) || null,
                                telefono_fijo: null,
                                telefono_celular: null,
                                correo_empresarial: data.mail_address || null,
                                pais: data.codigo_pais,
                                departamento:  Number(data.codigo_departamento_dane) || null,
                                ciudad:  Number(data.codigo_ciudad_dane) || null,
                                ciudad_nombre: data.city || null,
                                matricula_mercantil: null,
                                sagrilaft: null,
                                sarlaft: null,
                                prevension_lavados_activos: null,
                                //form 2
                                regimen_tributario: null,
                                responsable_iva: null,
                                //form 3
                                nombre_rep: "",
                                tipo_documento_rep: "",
                                numero_de_identidad_rep: "",
                                correo_electronico_rep: "",
                                numero_telefonico_rep: null,
                                persona_expuesta: "",
                                //form 4
                                tamano_empresa: "",
                                entidad_financiera: Number(data.bank_name) || null,
                                numero_cuenta: data.bank_account_type || null,
                                tipo_cuenta: "",
                                //form 5
                                nombre_contacto_financiero: "",
                                cargo_contacto_financiero: "",
                                telefono_contacto_financiero: "",
                                correo_electronico_financiero: "",
                                //form 6
                                nombre_contacto_comercial: data.nom_contacto || null,
                                cargo_contacto_comercial: "",
                                telefono_contacto_comercial: "",
                                correo_electronico_contacto_comercial: data.commercial_email.replace(',', '') || null,
                                expbienes_servicios: "",
                                //form 7
                                nombre_contacto_lg: "",
                                cargo_contacto_lg: "",
                                telefono_contacto_lg: "",
                                correo_electronico_contacto_lg: "",
                                //exterior
                                bussines_type: "",
                                contact_person_requestor: "",
                                office_tel_requestor: "",
                                email_requestor: "",
                                account_name_bankinfo: "", 
                                bank_name_bankinfo: "",
                                bank_branch_name_bankinfo: "",
                                bank_branch_aba_bankinfo: "",
                                currency_bankinfo: "",
                                swift_code_bankinfo: "",
                                bank_address_bankinfo: "",
                                //form documentos
                                iddir: data.iddir || [],
                                //habeas data
                                checkHabeasData: false,
                                
                                user_id: data.user_id,
                                name_dir: data.name_dir || "",
                                idparent: data.idparent || ""
                            }
                            dispatch(ActualizarForm(info));
                            dispatch(setDisabled(false));
                            dispatch(setDisabledNit(true));
                        }
                    })
                }

                if (i18next.isInitialized) {
                    showConfirmDialog();
                } else {
                    i18next.on('initialized', () => {
                        showConfirmDialog();
                    });
                }

            }
            dispatch(setLoading(false))
        } catch (error) {          
            toast.error(error?.response?.data.mns);
            dispatch(setLoading(false));
        }
    }
}
export const getTokenUser = (e) => {
    return async (dispatch, getState) => {          
        const { data } = await instanceAxios.get(`/v1/token/`, {
            params: {
                user_id: e.user_id || e
            }
        });
        const xhr = new instanceAxiosPrivate()
        xhr.setToken(data.token);
    }
}
export const getPais = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/pais`);
            let pais =  data.map(r => {
                return {
                    label: r.nombre_pais,
                    value: r.codigo_iso
                }
            });
            dispatch(setPaisDane(pais));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDepartamento = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/departamentodane`);
            let depart =  data.map(r => {
                return {
                    label: r.nombre_departamento,
                    value: Number(r.codigo_dane_departamento)
                }
            });
            dispatch(setDepartamentoDane(depart));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getCiudad = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/ciudaddane`);
            let city =  data.map(r => {
                return {
                    label: r.nombre_ciudad,
                    value: Number(r.codigo_dane_ciudad)
                }
            });
            dispatch(setCiudadDane(city));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDocumentType = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/documenttype`);
            let docttype =  data.map(r => {
                return {
                    label: r.doc_type_desc,
                    value: Number(r.doc_type_id)
                }
            });
            dispatch(setDocumentType(docttype));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getEfectosTributarios = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/efectostributarios`);
            let efectrib =  data.map(r => {
                return {
                    label: r.nombre_efecto_tributario,
                    value: Number(r.id_efecto_tributario)
                }
            });
            dispatch(setEfectosTributarios(efectrib));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getEfectosTributariosTarifa = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/efectostributariostarifa`);
            let efectribtarifa =  data.map(r => {
                return {
                    label: r.nombre_efecto_tributario_tarifa,
                    value: Number(r.id)
                }
            });
            dispatch(setEfectosTributariosTarifa(efectribtarifa));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getGlosarioTipoEmpresa = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/glosariotipoempresa`);
            let glotipemp =  data.map(r => {
                return {
                    label: r.nombre_tipo_empresa,
                    value: Number(r.id)
                }
            });
            dispatch(setGlosarioTipoEmpresa(glotipemp));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getGlosarioTipago = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/glosariotipago`);
            let glotippag =  data.map(r => {
                return {
                    label: r.dia_pago,
                    value: Number(r.dia_pago.split(' ')[0])
                }
            });
            dispatch(setGlosarioTipago(glotippag));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getCodigoPostal = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/codigopostal`);
            let codpostal =  data.map(r => {
                return {
                    label: `${r.nombre_codigo}`,
                    value: Number(r.id)
                }
            });
            dispatch(setCodigoPostal(codpostal));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRegimen = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/regimen`);
            let regdata =  data.map(r => {
                return {
                    label: r.nombre_regimen,
                    value: Number(r.id)
                }
            });
            dispatch(setRegimen(regdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getTypedocument = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/typedocumentvendor`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_documento,
                    value: Number(r.id)
                }
            });
            dispatch(setTypedocument(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRegimentributario = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/regimentributario`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_tributario,
                    value: Number(r.id)
                }
            });
            dispatch(setRegimentrib(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getBanks = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/bancos`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_banco,
                    value: Number(r.id)
                }
            });
            dispatch(setBanks(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRequired = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/requerido`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre,
                    value: Number(r.id)
                }
            });
            dispatch(setRequired(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDataFiles = (e) => {
    return async (dispatch, getState) => {
        try {
            const xhr = new instanceAxiosPrivate();

            const {data} = await xhr.services.get(`/v1/vendor/fsfilesrechazado?identification=` + e);
            dispatch(setFilesUpd(data));  
        } catch ({response}) {
            toast.error(response.data.mns);
        }
    }
}
export const updateVendor = () => {
    return async (dispatch, getState) => {
        const { form } = getState().form_vendor;
        let info = [];
        dispatch(setLoading(true))
        //filter by key
        const items = form.iddir.filter(item => item.requerid === 'S');
        // validar si hay elementos vacios
        const elementValid = items.some(obj => !obj.name_file);
        if(elementValid) {
            toast.error("Debe cargar todos los archivos requeridos");
            dispatch(setLoading(false))
            return
        }
        await dispatch(getTokenUser(form.numero_documento)); 
         // Create an array of promises for the uploads
         form.iddir.map(async (element) => {
            if (element.requerid === 'N' && element.name_file === null) {
                info = [...info, { ...element, ...element }];
                return; // skip uploading for this element
            }
            let promise = uploadFileDrive2(element, form.numero_documento);
            info = [...info, promise];
        });
        
        // Wait for all uploads to complete
        let allpromise = await Promise.all(info)
        const xhr = new instanceAxiosPrivate();
        let promisesarray = allpromise.map((item, index) => ({
            iddir : xhr.getItemGeneral('iddir')[index] || 0 ,
            ...item.data.data
        }) );


        let iddirs = form.iddir.map(originalItem => {
            const updateItem = promisesarray.find(item => item['iddir'] === originalItem['iddir']);
            return updateItem ? { ...originalItem, ...updateItem } : originalItem;
        });

        // update  state info
        dispatch(updArrayVal(iddirs));

        dispatch(sendForm());
    }
}

export const sendForm = () => {
    return async (dispatch, getState) => {   
        try {
            
            const { form } = getState().form_vendor;

            localStorage.setItem('form_vendor', JSON.stringify(form));
            const { data } = await instanceAxios.put(`/v1/vendor/updvendorprocess`, form);

            dispatch(setLoading(false))
            toast.success(data.msn);
            dispatch(setDisabled(true));
            setTimeout(() => {
                window.localStorage.clear();
                window.location.replace('/');
            }, 2000);
        } catch (error) {
            dispatch(setLoading(false))
            toast.error('Ocurrió un error al intentar enviar el formulario');
        }
    }
}

export const uploadFileDrive = (e,  nit = '', id_dir = 0) => {
    return async (dispatch, getState) => {      
        const {form} = getState().form_vendor;
        
        const xhr = new instanceAxiosPrivate()
        const formD = new FormData();
        const iddir = e.iddir || id_dir;
        const file = xhr.getItemGeneral(`iddir_${iddir }`);

        formD.append('files_drive', file);
        formD.append('parents', '1XL0d7WoUXeOOPyBROhAB1AdluJ-CRPh5');
        formD.append('suppliers', form.numero_documento || nit);
        formD.append('type', iddir || id_dir);
        
        const { data = {} } = await xhr.services.post(`/v1/managerFilesEvidencias`, formD );

        xhr.setItemGeneral( `${iddir}_obj`, data )
        return data;
    }
}

export const getDataToLocalStorage = () => {
    return async (dispatch, getState) => {
        try {            
            const data = JSON.parse(localStorage.getItem('form_vendor'));
            
            if (data && data.length !== null) {
                dispatch(ActualizarForm(data));
            }
        } catch (error) {
            toast.error(error);
        }
    }
}

export const updateObjectIddir = (e = {}) => {
    return async (dispatch, getState) => {
        const { form } = getState().form_vendor;

        let data = form.iddir.map((item) => (
            item.iddir === e.iddir ? { ...item, ...e } : item
        ))
        dispatch(updArrayVal(data))
        
    }
}

export const updateObjectFilesupd = (e = {}) => {
    return async (dispatch, getState) => {
        const { filesupd } = getState().form_vendor;

        let data = filesupd.map((item) => (
            item.iddir === e.iddir ? { ...item, ...e } : item
        ))
        dispatch(updArrayfilesVal(data))
        
    }
}

export const updateFilesRechazados = (e) => {
    return async (dispatch, getState) => {
        const { iddir } = e;        
        const { filesupd } = getState().form_vendor;
        const { perfil } = getState().user;
        const element = filesupd.filter(obj => obj.iddir === iddir)
        let info = element;
        try {
            dispatch(setLoading(true))
            const { data } = await dispatch(uploadFileDrive(info, perfil.identification, iddir))
            let dataupd = filesupd.filter(
                (item) => item.iddir === iddir
            ).map((item) => (
                item.iddir === iddir ? { ...item, ...data } : item
            ))
            dispatch(updateObjectFilesupd(...dataupd))
            await dispatch(sendFormFiles(iddir))            
        } catch (error) {
            dispatch(setLoading(false))
            toast.error(error.mns);
            return
        }
    }
}

export const sendFormFiles = (datau) => {
    return async (dispatch, getState) => {     
        let inf = [];
        const { filesupd } = getState().form_vendor;
        const { perfil } = getState().user;
        const xhr = new instanceAxiosPrivate()
        let dataupd = filesupd.filter((item) => item.iddir === datau)

        inf = [...inf, ...dataupd];

        const {data} = await xhr.services.post(`/v1/vendor/fsfilesupd`, inf, {
            headers: { 'Content-Type': 'application/json' } 
        }
        );  
        
        if(data){
            dispatch(setLoading(false));
            await dispatch(getDataFiles(perfil.identification));
            
            toast.success(data.msn);
            return data;
        }
        
    }
}

export const generatePDF = (obj) => {
    return async (dispatch, getState) => {     
        try {
            dispatch(setLoading(true));
            const { data }  = await instanceAxios.post(`/v1/generatepdf`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }); 
            dispatch(setLoading(false));
            dispatch(setPDF(process.env.REACT_APP_ENDPOINT + data.url.replace('/', '')))
        } catch (error) {
            toast.error(error);
            dispatch(setLoading(false));
            return
        }        
    }
}

export const sendFormTemp = () => {
    return async (dispatch, getState) => {     
        const { form } = getState().form_vendor;
        dispatch(setLoading(true));
        const { data } = await instanceAxios.put(`/v1/vendor/tempvendordata`, form);
        if(data){
            dispatch(setLoading(false))
            toast.success(data.msn);
            
        }
    }
}

export const uploadFileDrive2 = (e,  nit = '') => {
    const xhr = new instanceAxiosPrivate() 
    const formD = new FormData();
    const iddir = e.iddir ;
    const file = xhr.getItemGeneral(`iddir_${iddir }`);


    formD.append('files_drive', file);
    formD.append('parents', '1XL0d7WoUXeOOPyBROhAB1AdluJ-CRPh5');
    formD.append('suppliers', nit);
    formD.append('type', iddir);
   
    let h = xhr.getItemGeneral('iddir') || [] 
    let valAnt = [...h, iddir] 
    xhr.setItemGeneral('iddir', valAnt)

    return xhr.servicespost('v1/managerFilesEvidencias', formD)
}

export const getData = (e) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/getvendordata`, {
                params: {
                    numero_documento: e
                }
            }); 
            dispatch(ActualizarForm(data));
            dispatch(setDisabled(false));
            dispatch(setLoading(false));
        } catch (error) {          
            toast.error(error?.response?.data.mns);
            dispatch(setLoading(false));
        }
    }
}

export const sendFormupd = () => {
    return async (dispatch, getState) => {  
        const { form } = getState().form_vendor;

        try {
            dispatch(setLoading(true))
            const { data } = await instanceAxios.put(`/v1/vendor/updvendorjson`, form);
            dispatch(setLoading(false))
            toast.success(data.msn);
            dispatch(getData(form.numero_documento))
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
            toast.error('Ocurrió un error al intentar enviar el formulario');
        }
    }
}