import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getDataFiles } from '../../../slices/vendor/vendorThunks';
import UploadFile from '../../Cards/UploadFile';

const Vendor = () => {
  const dispatch = useDispatch();
  const { filesupd } = useSelector((state) => state.form_vendor);
  const { perfil } = useSelector((state) => state.user);

  useEffect(() => {
    if(perfil.identification !== undefined){
      dispatch(getDataFiles(perfil.identification));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, perfil.identification])

  return (
    <>
      <div className='row'>
          <div className='col-12'>
            <div className="row my-2">
              <div className="col-sm-6">
              <h1 className="m-0">{filesupd.length > 0  ? 'Actualización archivos' : 'No tienes archivos pendientes'} </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Proveedores</li>
                  <li className="breadcrumb-item active">Actualización archivos</li>
                </ol>
              </div>
            </div>
            <hr />
            <div className="row">
              
              {
                filesupd.map((r) => (
                  <div className='col-12 col-md-6 mt-4' key={r.idfile}>  
                    <UploadFile data={r} />
                  </div>
                ))
              }
            </div>
          </div>
      </div>
    </>
  )
}

export default Vendor;