import React, { useEffect, useRef, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//thunks
import { ActualizarForm, setDisabled } from "../../slices/vendor/vendorSlice";
import {
  getDataToLocalStorage,
  updateVendor,
  updateObjectIddir,
  generatePDF,
  sendFormTemp,
} from "../../slices/vendor/vendorThunks";

//validation
import { generateValidationSchema } from "../../utils/validationsSchemaVendorExtranjero";
//axios
import { instanceAxiosPrivate } from "../../axios";
import Modal from "../modal/Modal";

const Checkbox = lazy(() => import("../form/checkbox/Checkbox"));

export const Extranjero = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //traduccion
  const { t } = useTranslation();
  //state
  const [validationSchema, setValidationSchema] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalPreliminar, setShowModalPreliminar] = useState(false);
  //data formulario
  const {
    form,
    disabled,
    pdf
  } = useSelector((state) => state.form_vendor);
  //referencia
  const check_habeas_data = useRef();
  //modales
  const modalHide = () => {
    setShowModal(false);
  };
  const modalShow = () => {
    setShowModal(true);
  };
  const modalHidePreliminar = () => {
    setShowModalPreliminar(false);
  };
  const modalShowPreliminar = () => {
    setShowModalPreliminar(true);
  };
  //form
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });
  //preliminar form
  const onPreview = async () => {
    let form = document.querySelector("form");
    const formData = new FormData(form);
    let values = Object.fromEntries(formData);
    let valform = values;
    valform.numero_documento = getValues("numero_documento");
    // validate numero de documento
    let obj = {
      templatefile: "formulario_registro",
      values: valform,
      filename: `register_vendor_${getValues("numero_documento")}`,
      format: "A4",
    };
    //generate pdf
    dispatch(generatePDF(obj));
    //show modal with pdf
    setTimeout(() => {
      modalShowPreliminar();
    }, 2500);
  };
  //send form
  const onSubmit = async (values) => {
    for (var key in values) if (key.startsWith("iddir_")) delete values[key];
    for (var llave in values) if (llave.startsWith("origen_")) delete values[llave];
    values.draft='N';
    values.correo_empresarial = form.correo_electronico_contacto_comercial;
    values.pais = "CO";
    values.telefono_celular = "313524";
    values.departamento = 11;
    values.ciudad = 11001;
    values.ciudad_nombre = "BOGOTÁ";

    dispatch(ActualizarForm(values));
    Swal.fire({
      title: t("sure"),
      text: t("suretext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("sureyesbutton"),
      cancelButtonText: t("surecancelbutton"),
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(updateVendor());
        } catch (error) {
          toast.error(error);
        }
      }
    });
  };
  //send form
  const onTemp = async () => {
    let form = document.querySelector("form");
    const formData = new FormData(form);
    let values = Object.fromEntries(formData);
    values.draft = "S";
    Swal.fire({
      title: t("suretexttemp"),
      text: t("sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("sureyestmpbutton"),
      cancelButtonText: t("surecancelbutton"),
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(ActualizarForm(values));
          dispatch(sendFormTemp());
        } catch (error) {
          toast.error(error);
        }
      }
    });
  };
  // save file window
  const handleFileChange = (event, r) => {
    const file = event.target.files[0];
    //guardar el archivo
    const instA = new instanceAxiosPrivate();
    instA.setItemGeneral(`iddir_${r.iddir}`, file);
    if (file) {
      setValue(`iddir_${r.iddir}`, instA.getItemGeneral(`iddir_${r.iddir}`));
    }
    //actualizar el state
    dispatch(
      updateObjectIddir({
        iddir: r.iddir,
        name_dir: r.name_dir,
        idparent: r.idparent,
        name_file: file.name,
        mime: r.mime,
        size: file.size,
        type_document: 1,
      })
    );
  };
  //reset values localstorage
  useEffect(() => {
    const localS = localStorage.getItem("form_vendor");
    const localSparse = JSON.parse(localS);
    if (localSparse?.numero_documento !== 0 && localSparse !== null) {
      reset({
        //form 1
        numero_documento: localSparse?.numero_documento,
        tipo_compania: localSparse?.tipo_compania,
        tipo_documento: localSparse?.tipo_documento,
        razon_social: localSparse?.razon_social,
        direccion_principal: localSparse?.direccion_principal,
        codigo_postal: localSparse?.codigo_postal,
        telefono_fijo: localSparse?.telefono_fijo,
        telefono_celular: localSparse?.telefono_celular,
        correo_empresarial: localSparse?.correo_empresarial,
        pais: localSparse?.pais,
        departamento: localSparse?.departamento,
        ciudad: localSparse?.ciudad,
        ciudad_nombre: localSparse?.ciudad_nombre,
        matricula_mercantil: localSparse?.matricula_mercantil,
        sagrilaft: localSparse?.sagrilaft,
        sarlaft: localSparse?.sarlaft,
        prevension_lavados_activos: localSparse?.prevension_lavados_activos,
        //form 2
        regimen_tributario: localSparse?.regimen_tributario,
        responsable_iva: localSparse?.responsable_iva,
        //form 3
        nombre_rep: localSparse?.nombre_rep,
        tipo_documento_rep: localSparse?.tipo_documento_rep,
        numero_de_identidad_rep: localSparse?.numero_de_identidad_rep,
        correo_electronico_rep: localSparse?.correo_electronico_rep,
        numero_telefonico_rep: localSparse?.numero_telefonico_rep,
        persona_expuesta: localSparse?.persona_expuesta,
        //form 4
        tamano_empresa: localSparse?.tamano_empresa,
        entidad_financiera: localSparse?.entidad_financiera,
        numero_cuenta: localSparse?.numero_cuenta,
        tipo_cuenta: localSparse?.tipo_cuenta,
        //form 5
        nombre_contacto_financiero: localSparse?.nombre_contacto_financiero,
        cargo_contacto_financiero: localSparse?.cargo_contacto_financiero,
        telefono_contacto_financiero: localSparse?.telefono_contacto_financiero,
        correo_electronico_financiero:
          localSparse?.correo_electronico_financiero,
        //form 6
        nombre_contacto_comercial: localSparse?.nombre_contacto_comercial,
        cargo_contacto_comercial: localSparse?.cargo_contacto_comercial,
        telefono_contacto_comercial: localSparse?.telefono_contacto_comercial,
        correo_electronico_contacto_comercial:
          localSparse?.correo_electronico_contacto_comercial,
        expbienes_servicios: localSparse?.expbienes_servicios,
        //form 7
        nombre_contacto_lg: localSparse?.nombre_contacto_lg,
        cargo_contacto_lg: localSparse?.cargo_contacto_lg,
        telefono_contacto_lg: localSparse?.telefono_contacto_lg,
        correo_electronico_contacto_lg:
          localSparse?.correo_electronico_contacto_lg,
        check_habeas_data: localSparse?.check_habeas_data,
        //exterior
        bussines_type: localSparse?.bussines_type,
        contact_person_requestor: localSparse?.contact_person_requestor,
        office_tel_requestor: localSparse?.office_tel_requestor,
        email_requestor: localSparse?.email_requestor,
        account_name_bankinfo: localSparse?.account_name_bankinfo, 
        bank_name_bankinfo: localSparse?.bank_name_bankinfo,
        bank_branch_name_bankinfo: localSparse?.bank_branch_name_bankinfo,
        bank_branch_aba_bankinfo: localSparse?.bank_branch_aba_bankinfo,
        currency_bankinfo: localSparse?.currency_bankinfo,
        swift_code_bankinfo: localSparse?.swift_code_bankinfo,
        bank_address_bankinfo: localSparse?.bank_address_bankinfo
      });
      dispatch(getDataToLocalStorage());
      dispatch(setDisabled(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //reset values redux
  useEffect(() => {
    if (form.numero_documento !== 0) {
      reset({
        //form 1
        numero_documento: form?.numero_documento,
        tipo_compania: form?.tipo_compania,
        tipo_documento: form?.tipo_documento,
        razon_social: form?.razon_social,
        direccion_principal: form?.direccion_principal,
        codigo_postal: form?.codigo_postal,
        telefono_fijo: form?.telefono_fijo,
        telefono_celular: form?.telefono_celular,
        correo_empresarial: form?.correo_empresarial,
        pais: form?.pais,
        departamento: form?.departamento,
        ciudad: form?.ciudad,
        ciudad_nombre: form?.ciudad_nombre,
        matricula_mercantil: form?.matricula_mercantil,
        sagrilaft: form?.sagrilaft,
        sarlaft: form?.sarlaft,
        prevension_lavados_activos: form?.prevension_lavados_activos,
        //form 2
        regimen_tributario: form?.regimen_tributario,
        responsable_iva: form?.responsable_iva,
        //form 3
        nombre_rep: form?.nombre_rep,
        tipo_documento_rep: form?.tipo_documento_rep,
        numero_de_identidad_rep: form?.numero_de_identidad_rep,
        correo_electronico_rep: form?.correo_electronico_rep,
        numero_telefonico_rep: form?.numero_telefonico_rep,
        persona_expuesta: form?.persona_expuesta,
        //form 4
        tamano_empresa: form?.tamano_empresa,
        entidad_financiera: form?.entidad_financiera,
        numero_cuenta: form?.numero_cuenta,
        tipo_cuenta: form?.tipo_cuenta,
        //form 5
        nombre_contacto_financiero: form?.nombre_contacto_financiero,
        cargo_contacto_financiero: form?.cargo_contacto_financiero,
        telefono_contacto_financiero: form?.telefono_contacto_financiero,
        correo_electronico_financiero: form?.correo_electronico_financiero,
        //form 6
        nombre_contacto_comercial: form?.nombre_contacto_comercial,
        cargo_contacto_comercial: form?.cargo_contacto_comercial,
        telefono_contacto_comercial: form?.telefono_contacto_comercial,
        correo_electronico_contacto_comercial:
          form?.correo_electronico_contacto_comercial,
        expbienes_servicios: form?.expbienes_servicios,
        //form 7
        nombre_contacto_lg: form?.nombre_contacto_lg,
        cargo_contacto_lg: form?.cargo_contacto_lg,
        telefono_contacto_lg: form?.telefono_contacto_lg,
        correo_electronico_contacto_lg: form?.correo_electronico_contacto_lg,
        check_habeas_data: form?.check_habeas_data,
        //exterior
        bussines_type: form?.bussines_type,
        contact_person_requestor: form?.contact_person_requestor,
        office_tel_requestor: form?.office_tel_requestor,
        email_requestor: form?.email_requestor,
        account_name_bankinfo: form?.account_name_bankinfo, 
        bank_name_bankinfo: form?.bank_name_bankinfo,
        bank_branch_name_bankinfo: form?.bank_branch_name_bankinfo,
        bank_branch_aba_bankinfo: form?.bank_branch_aba_bankinfo,
        currency_bankinfo: form?.currency_bankinfo,
        swift_code_bankinfo: form?.swift_code_bankinfo,
        bank_address_bankinfo: form?.bank_address_bankinfo
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, form?.numero_documento]);
  //validaciones
  useEffect(() => {
    if (form.iddir.length > 0) {
      const schema = generateValidationSchema(form.iddir);
      setValidationSchema(schema);
    }
  }, [form.iddir]);

  return (
    <>
      <Modal
        handleClose={modalHide}
        isOpen={showModal}
        header={t("titleauth")}
        invoiceNum={" "}
      >
        <div className="container-fluid py-2">
          <p className="text-justify">
            {t("auth1")}
            <Link
              to="https://www.lg.com/co/privacy/#tabs-privacy-policy"
              target="_blank"
              className="text-decoration-none text-gray font-weight-bold"
            >
              {t("politic_data")}
            </Link>
            {t("of")} LG ELECTRONICS COLOMBIA LTDA.
          </p>
          <p className="text-justify">{t("auth2")}</p>
        </div>
      </Modal>
      <Modal
        handleClose={modalHidePreliminar}
        isOpen={showModalPreliminar}
        header=""
        invoiceNum={" "}
      >
        <div className="container-fluid py-2 justify-content-center">
          {pdf !== "" ? (
            <embed
              type="application/pdf"
              src={pdf}
              title="Registro Preliminar"
              width="900"
              height="600"
              style={{ width: "100%" }}
            />
          ) : (
            <p> No hay datos </p>
          )}
        </div>
      </Modal>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
        <div className="row py-1">
          <div className="col-12">
            <h5 className="mt-2 mb-0">{t("general_supplier_information")}</h5>
            <hr className="col-12 ml-0 my-1 px-0 py-0" />
          </div>

          <div className='col-12 col-md-6 '>
            <label>{t('name_or_company_name')}</label>  
            <input type="text" className='form-control'  {...register("razon_social")} disabled={disabled}/>
            {errors.razon_social && (<p className='text-danger mb-0'>{errors ? errors.razon_social?.message : ''}</p>)}
          </div>
          <div className='col-12 col-md-6'>
            <label>{t('primary_home_address')}</label>  
            <input type="text" className='form-control' {...register("direccion_principal") } disabled={disabled}/>
            {errors.direccion_principal && (<p className='text-danger mb-0'>{errors ? errors.direccion_principal?.message : ''}</p>)}
          </div>
          <div className='col-12 col-md-6'>
            <label>{t('bussinest')}</label>  
            <input type="text" className='form-control' {...register("bussines_type") } disabled={disabled}/>
            {errors.bussines_type && (<p className='text-danger mb-0'>{errors ? errors.bussines_type?.message : ''}</p>)}
          </div>
          <div className='col-12 col-md-6'>
            <label>{t('commercial_date')}</label>   
            <input type="date" className='form-control' {...register("matricula_mercantil")} disabled={disabled}/>
            {errors.matricula_mercantil && (<p className='text-danger mb-0'>{errors ? errors.matricula_mercantil?.message : ''}</p>)}
          </div>
        </div>
        <div className="row py-1">
          <h5 className='mt-2 mb-0'>{t('requesdep')}</h5>
          <hr className="col-11 ml-2 my-1 py-0"/>
          {/* nuevos campos */}
          <div className="col-12 col-md-6">
            <label>{t('contacp')}</label>  
            <input type="text" className='form-control' {...register("contact_person_requestor")} disabled={disabled}/>
            {errors.contact_person_requestor && (<p className='text-danger mb-0'>{errors ? errors.contact_person_requestor?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('officetel')}</label>    
            <input type="number" className='form-control' {...register("office_tel_requestor")} disabled={disabled}/>
            {errors.office_tel_requestor && (<p className='text-danger mb-0'>{errors ? errors.office_tel_requestor?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('emailc')}</label>  
            <input type="text" className='form-control' {...register("email_requestor")} disabled={disabled}/>
            {errors.email_requestor && (<p className='text-danger mb-0'>{errors ? errors.email_requestor?.message : ''}</p>)}
          </div>
        </div>
        <div className="row py-1">
          <h5 className='mt-2 mb-0'>{t('finacedep')}</h5>
          <hr className="col-11 ml-2 my-1 py-0"/>
          {/* nuevos campos */}
          <div className="col-12 col-md-6">
            <label>{t('contacp')}</label>  
            <input type="text" className='form-control' {...register("nombre_contacto_comercial")} disabled={disabled}/>
            {errors.nombre_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.nombre_contacto_comercial?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('officetel')}</label>    
            <input type="number" className='form-control' {...register("telefono_contacto_comercial")} disabled={disabled}/>
            {errors.telefono_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.telefono_contacto_comercial?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('emailc')}</label>  
            <input type="text" className='form-control' {...register("correo_electronico_contacto_comercial")} disabled={disabled}/>
            {errors.correo_electronico_contacto_comercial && (<p className='text-danger mb-0'>{errors ? errors.correo_electronico_contacto_comercial?.message : ''}</p>)}
          </div>
        </div>
        <div className="row py-1">
          <h5 className='mt-2 mb-0'>{t('representative')}</h5>
          <hr className="col-11 ml-2 my-1 py-0"/>
          {/* nuevos campos */}
          <div className="col-12 col-md-6">
            <label>{t('contacp')}</label>  
            <input type="text" className='form-control' {...register("nombre_rep")} disabled={disabled}/>
            {errors.nombre_rep && (<p className='text-danger mb-0'>{errors ? errors.nombre_rep?.message : ''}</p>)}
          </div>
        </div> 
        <div className="row py-1">
          <h5 className='mt-2 mb-0'>{t('bankinf')}</h5>
          <hr className="col-11 ml-2 my-1 py-0"/>
          {/* nuevos campos */}
          <div className="col-12 col-md-6">
            <label>{t('accname')}</label>  
            <input type="text" className='form-control' {...register("account_name_bankinfo")} disabled={disabled}/>
            {errors.account_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.account_name_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('bankn')}</label>  
            <input type="text" className='form-control' {...register("bank_name_bankinfo")} disabled={disabled}/>
            {errors.bank_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_name_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('bankbn')}</label>  
            <input type="text" className='form-control' {...register("bank_branch_name_bankinfo")} disabled={disabled}/>
            {errors.bank_branch_name_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_branch_name_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('bankbar')}</label>  
            <input type="text" className='form-control' {...register("bank_branch_aba_bankinfo")} disabled={disabled}/>
            {errors.bank_branch_aba_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_branch_aba_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('bankaccn')}</label>  
            <input type="text" className='form-control' {...register("numero_cuenta")} disabled={disabled}/>
            {errors.numero_cuenta && (<p className='text-danger mb-0'>{errors ? errors.numero_cuenta?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('currency')}</label>  
            <input type="text" className='form-control' {...register("currency_bankinfo")} disabled={disabled}/>
            {errors.currency_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.currency_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('scode')}</label>  
            <input type="text" className='form-control' {...register("swift_code_bankinfo")} disabled={disabled}/>
            {errors.swift_code_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.swift_code_bankinfo?.message : ''}</p>)}
          </div>
          <div className="col-12 col-md-6">
            <label>{t('banka')}</label>  
            <input type="text" className='form-control' {...register("bank_address_bankinfo")} disabled={disabled}/>
            {errors.bank_address_bankinfo && (<p className='text-danger mb-0'>{errors ? errors.bank_address_bankinfo?.message : ''}</p>)}
          </div>
        </div> 

        {form.iddir.length > 0 ? (
          <div className="row py-1">
            <h5 className="mt-2 mb-0">{t("upload")}</h5>
            <hr className="col-11 ml-2 my-1 py-0" />
            {form.iddir.map((r) => (
              <div className="col-12 col-md-6 py-2" key={r.name_dir}>
                <div>
                  <label>
                    {r.name_dir}
                    <span className="text-red">
                      {r.requerid === "S" ? "*" : ""}
                    </span>
                  </label>
                </div>
                <div className="col-12 col-md-12 px-0 py-0">
                  <Controller
                    name={`iddir_${r.iddir}`}
                    control={control}
                    rules={{
                      required: r.requerid === "S" ? true : false,
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <>
                        <input
                          type="file"
                          id={`iddir_${r.iddir}`}
                          accept="application/pdf"
                          className="form-control"
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                            handleFileChange(event, r);
                          }}
                          ref={ref}
                        />
                        {errors[`iddir_${r.iddir}`] && (
                          <p className="text-danger mb-0">
                            {errors[`iddir_${r.iddir}`].message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        <div className="row py-1">          
          <div className="py-2">
            <div className="row">
              <div className="col-12 d-flex flex-row gap-3 ml-2">
                <Controller
                  control={control}
                  name="check_habeas_data"
                  render={({ field: { onChange, value, name } }) => (
                    <Checkbox
                      name={name}
                      ref={check_habeas_data}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      className="form-check"
                      disabled={disabled}
                    />
                  )}
                />
                <button
                  type="button"
                  className="text-decoration-none text-black btn btn-link m-0 p-0"
                  onClick={modalShow}
                >
                  {t("authorization_personal_data")}
                </button>
              </div>
              <div className="col-12">
                {errors.check_habeas_data && (
                  <p className="text-danger mb-0">
                    {errors ? errors.check_habeas_data?.message : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-1">
          <div className="col-12 col-sm-4 col-md-4 my-2">
            <button
              type="button"
              className="btn btn-secondary btn-flat float-left"
              onClick={() => navigate("/login")}
            >
              {t("back")}
            </button>
          </div>
          <div className="col-12 col-sm-8 col-md-8 my-2">
            {form?.iddir.length > 0 ? (
              <button
                type="button"
                className="btn btn-danger btn-flat float-right ml-1 text-uppercase"
                onClick={handleSubmit(onSubmit)}
                disabled={disabled}
              >
              {t("save")}
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              className="btn btn-secondary btn-flat float-right ml-1 mr-1 text-uppercase"
              onClick={onTemp}
              disabled={disabled}
            >
              {t("temp")}
            </button>

            <button
              type="button"
              className="btn btn-secondary btn-flat float-right mr-1"
              //onClick={handleSubmit(onPreview)}
              onClick={onPreview}
              disabled={disabled}
            >
              PDF
            </button>
          </div>
        </div>        
      </form>
    </>
  );
};
