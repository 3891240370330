import axios from "axios"

// use endpoint Public
export const instanceAxios = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/`
})


export const existToken = () => {
    if (localStorage.getItem('token') === null && localStorage.getItem('token') === undefined) {
        return;        
    }    
    return localStorage.getItem('token')
}

// use endpoint Private
export const instanceAxiosPrivate3 = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/`,
    headers: {'x-token-lg': existToken()}
})


// use endpoint Private
export const instanceAxiosPrivate = (() => {
    
    function instanceAxiosPrivate(){
        this.token = localStorage.getItem('token')
        this.baseURL = `${process.env.REACT_APP_ENDPOINT}`
        this.services = axios.create({
            baseURL: `${process.env.REACT_APP_ENDPOINT}/`,
            headers: {
                'x-token-lg' : this.token ? this.token : '',
                'Content-Type': 'multipart/form-data'
            }
        })
        this.servicespost = (url = '', data = {} ) =>{ 
            return axios.post(`${process.env.REACT_APP_ENDPOINT}${url}`, data,
                {
                headers: {
                    'x-token-lg' : this.token ? this.token : '',
                    'Content-Type': 'multipart/form-data'
                }
            } )
        }
    }

    instanceAxiosPrivate.prototype.getToken = function() {
        return this.token
    }
    
    instanceAxiosPrivate.prototype.setToken = (strToken = '') => {
        localStorage.setItem('token',strToken)
        instanceAxiosPrivate.prototype.token = strToken
    }

    //delete token
    instanceAxiosPrivate.prototype.removeToken = () => {
        localStorage.removeItem('token')
    }

    // add formdata
    instanceAxiosPrivate.prototype.addCustomHeader = (key, value) => {

        if(this.services){
            this.services.defaults.headers[key] = value;
        } else {
            console.error("La instancia de Axios no está inicializada.");
        }
    }
    

    // Set itemGeneral
    instanceAxiosPrivate.prototype.setItemGeneral = (key , value) => {
        window[key] = value;
    }
    
    instanceAxiosPrivate.prototype.getItemGeneral = (key) => {
        return window[key];
    }

    return instanceAxiosPrivate
})()

