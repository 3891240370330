import { instanceAxiosPrivate  } from "../../axios";
import { toast } from "react-toastify";

import {
    setFacturas,
    setFacturasDetails,
    setPagos,
    setPagosProveedor,
    setDetalles,
    setLoading
} from './pagosSlice';


export const facturasProveedor = () => {
    return async (dispatch, getState) => {
        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/facturas`);
            dispatch(setFacturas(data));  
            dispatch(setLoading(true));
            //toast.success("MY SUCCESS");
        } catch (error) {
            toast.error(error.message);
        }
    }
}

export const facturasporfechaProveedor = (e) => {
    return async (dispatch,getState) => {
        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/facturasbyrange`, {
                params: {
                    initial_date: e.initial_date || null,
                    end_date: e.end_date || null,
                    invoice_num: e.invoice_num || null,
                    payment_num: e.payment_num || null
                }
            });
            dispatch(setFacturas(data));  
            dispatch(setLoading(true));
            //toast.success("MY SUCCESS");
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const pagosProveedor = () => {
    return async (dispatch, getState) => {
        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/pagos`);            
            dispatch(setPagosProveedor(data));
            dispatch(setLoading(true));
            //toast.success("MY SUCCESS");
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const pagosporfechaProveedor = (e) => {
    return async (dispatch, getState) => {

        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/pagosbyrange`, {
                params: {
                    initial_date: e.initial_date || null,
                    end_date:  e.end_date || null
                }
            });
            dispatch(setPagosProveedor(data));  
            dispatch(setLoading(true));
            //toast.success("MY SUCCESS");
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const pagosFactura = (e) => {
    return async (dispatch, getState) => {
        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/pagos/${e}`);
            dispatch(setPagos(data));  
            dispatch(setLoading(true));
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const detallesFactura = (e) => {
    return async (dispatch, getState) => {
        try {
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/detaill/${e}`)            
            dispatch(setDetalles(data));  
            dispatch(setLoading(true));                                
        } catch (error) {
            toast.error(error.message);
        }
    }
}

export const detallesFacturaPagos = (e) => {
    return async (dispatch, getState) => {
        try {            
            const instAxiosPrivate = new instanceAxiosPrivate()  
            const { data } = await instAxiosPrivate.services.get(`/v1/proveedores/detaill/${e}`)
            dispatch(setFacturasDetails({
                ...getState().pagos.facturasDetails || {},
                [e]: data                
            }))
            dispatch(setLoading(true));                                
        } catch (error) {
            toast.error(error.message);
        }
    }
}
