import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'


import Facturas from '../pages/facturas';
import Pagos from '../pages/Pagos';
import Vendor from '../../VENDOR/pages/vendor';
import Updatedata from '../../VENDOR/pages/updatedata';


export const PagosRoutes = () => {
  return (
    <Routes>
      <Route path="proveedores" errorElement={<ErrorBoundary />}>
        <Route path='facturas' element={<Facturas/>} />
        <Route path='pagos' element={<Pagos/>} />
        <Route path='formupdate' element={<Vendor/>} />
        <Route path='updvendor' element={<Updatedata/>} />
      </Route>    
    </Routes>
  )
}
