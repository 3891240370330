import { createSlice } from "@reduxjs/toolkit";

export const vendorSlice = createSlice({
    name: 'form_vendor',
    initialState: {
        form: {
            origen: "",
            //form 1
            numero_documento: "",
            tipo_compania: null,
            tipo_documento: null,
            razon_social: "",
            direccion_principal: "",
            codigo_postal: null,
            telefono_fijo: "",
            telefono_celular: "",
            correo_empresarial: "",
            pais: "",
            departamento: null,
            ciudad: null,
            ciudad_nombre: "",
            matricula_mercantil: "",
            sagrilaft: null,
            sarlaft: null,
            prevension_lavados_activos: null,
            //form 2
            regimen_tributario: null,
            responsable_iva: null,
            //form 3
            nombre_rep: "",
            tipo_documento_rep: null,
            numero_de_identidad_rep: "",
            correo_electronico_rep: "",
            numero_telefonico_rep: "",
            persona_expuesta: null,
            //form 4
            tamano_empresa: null,
            entidad_financiera: null,
            numero_cuenta: "",
            tipo_cuenta: null,
            //form 5
            nombre_contacto_financiero: "",
            cargo_contacto_financiero: "",
            telefono_contacto_financiero: "",
            correo_electronico_financiero: "",
            //form 6
            nombre_contacto_comercial: "",
            cargo_contacto_comercial: "",
            telefono_contacto_comercial: "",
            correo_electronico_contacto_comercial: "",
            expbienes_servicios: "",
            //form 7
            nombre_contacto_lg: "",
            cargo_contacto_lg: "",
            telefono_contacto_lg: "",
            correo_electronico_contacto_lg: "",
            checkHabeasData: false, 
            //exterior
            bussines_type: "",
            contact_person_requestor: "",
            office_tel_requestor: "",
            email_requestor: "",
            account_name_bankinfo: "", 
            bank_name_bankinfo: "",
            bank_branch_name_bankinfo: "",
            bank_branch_aba_bankinfo: "",
            currency_bankinfo: "",
            swift_code_bankinfo: "",
            bank_address_bankinfo: "",
            //form documentos
            iddir: [],

            user_id: null,
            name_dir: "",
            idparent: ""
        },
        filesupd: [],
        //
        paisdane: [],
        departamentodane: [],
        ciudaddane: [],
        documenttype: [],
        efectostributarios: [],
        glosariotipoempresa: [],
        efectostributariostarifa: [],
        glosariotipago: [],
        codigopostal: [],
        regimenlist: [],
        regimentrib: [],
        typedocument: [],
        banklist: [],
        required: [],
        pdf: '',
        //
        loading: false,
        disabled: true,
        disablednit: false,
    },
    reducers: {
        ActualizarForm: (state, { payload }) => {
            state = Object.assign(state.form, payload);
        },
        setPaisDane: (state, { payload }) => {
            state.paisdane = payload;
        },
        setDepartamentoDane: (state, { payload }) => {
            state.departamentodane = payload;
        },
        setCiudadDane: (state, { payload }) => {
            state.ciudaddane = payload;
        },
        setDocumentType: (state, { payload }) => {
            state.documenttype = payload;
        },
        setEfectosTributarios: (state, { payload }) => {
            state.efectostributarios = payload;
        },
        setEfectosTributariosTarifa: (state, { payload }) => {
            state.efectostributariostarifa = payload;
        },
        setGlosarioTipoEmpresa: (state, { payload }) => {
            state.glosariotipoempresa = payload;
        },
        setGlosarioTipago: (state, { payload }) => {
            state.glosariotipago = payload;
        },
        setCodigoPostal: (state, { payload }) => {
            state.codigopostal = payload;
        },
        setRegimen: (state, { payload }) => {
            state.regimenlist = payload;
        },
        setRegimentrib: (state, { payload }) => {
            state.regimentrib = payload;
        },
        setTypedocument: (state, { payload }) => {
            state.typedocument = payload;
        },
        setBanks: (state, { payload }) => {
            state.banklist = payload;
        },
        setRequired: (state, { payload }) => {
            state.required = payload;
        },
        updArrayVal: (state, {payload}) => {
            state.form.iddir = payload;
        },
        setFilesUpd: (state, { payload }) => {
            state.filesupd = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setDisabled: (state, { payload }) => {
            state.disabled = payload;
        },
        setDisabledNit: (state, { payload }) => {
            state.disablednit = payload;
        },
        updArrayfilesVal: (state, {payload}) => {
            state.filesupd = payload;
        },
        setPDF: (state, { payload }) => {
            state.pdf = payload;
        },
    },
});

export const {
    ActualizarForm,
    setPaisDane,
    setDepartamentoDane,
    setCiudadDane,
    setDocumentType,
    setEfectosTributarios,
    setGlosarioTipoEmpresa,
    setEfectosTributariosTarifa,
    setGlosarioTipago,
    setCodigoPostal,
    setRegimen,
    setRegimentrib,
    setTypedocument,
    setBanks,
    setRequired,
    updArrayVal,
    setFilesUpd,
    updArrayfilesVal,
    setLoading,
    setDisabled,
    setDisabledNit,
    setPDF
} = vendorSlice.actions;